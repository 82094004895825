import { Button } from "antd";
import { isEmpty } from "helpers/text.helper";
import moment from "moment";
import React from "react";
import NotificationsService from "services/utils/notifycationHelper";
import Modal from "../../../../../components/modal";
import { apiAdminCreateUser } from "../create-order.service";
import "./index.scss";

export default class ModalAddUserComponent extends React.PureComponent {
  fullName = "";
  phone = "";

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  open = () => {
    this.setState({ isOpen: true });
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onChangeValue = (key) => (value) => {
    this[key] = value.target.value;
  };

  onChangeStartDate = (date) => {
    this.dateSelect = moment(date).format("YYYY/MM/DD");
  };

  onChangeStartTime = (time) => {
    this.timeSelect = moment(time).format("HH:mm");
  };

  onAdd = async () => {
    const params = {
      fullName: this.fullName,
      phone: this.phone,
      password: "12345678",
      linkAffiliate: "0926821595",
      groupCustomerId: "5f89534be5cba81f4e6082e6",
      facebookId: "",
      googleId: "",
    };

    if (isEmpty(params.fullName)) {
      NotificationsService.error("Tên người dùng không được để trống");
      return;
    }

    if (isEmpty(params.phone)) {
      NotificationsService.error("Số điện thoại không được để trống");
      return;
    }

    const res = await apiAdminCreateUser(params);

    if (res.code === 200) {
      this.onClose();
      this.props?.onCreateUserSuccess?.(res?.data?.user);
    } else {
      NotificationsService.error(
        res?.message || "Thêm mới người dùng thất bại"
      );
    }
  };

  render() {
    const { isOpen } = this.state;

    if (!this.state.isOpen) return null;

    return (
      <Modal
        isOpen={isOpen}
        displayCloseIcon={false}
        setIsOpen={this.onClose}
        modalStyle={{
          width: "50%",
          maxHeight: "70%",
        }}
      >
        <div className="ModalAddLevelUser">
          <div className="MALU_Label">Thêm mới người dùng</div>
          <div>
            <label className="MALUF_Label">Tên người dùng</label>
            <input
              type="text"
              className="MALUF_Value"
              onChange={this.onChangeValue("fullName")}
              maxLength={30}
            />
          </div>
          <div>
            <label className="MALUF_Label">Số điện thoại</label>
            <input
              type="text"
              className="MALUF_Value"
              onChange={this.onChangeValue("phone")}
              maxLength={12}
            />
          </div>

          <div className="d-flex justify-content-center MALUF_Action">
            <Button type="dashed" className="mr-3" onClick={this.onClose}>
              Huỷ
            </Button>

            <Button type="primary" onClick={this.onAdd}>
              Thêm
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
