import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../../../../../components/modal";
import AdminCreateOrderActions from "../../../../redux/admin_create_order/actions";
import "./index.scss";

class ModalSelectAddress extends Component {
  render() {
    let { modalSelectAddressState, list_address, onSelectAddress } = this.props;

    return (
      <Modal
        isOpen={modalSelectAddressState}
        setIsOpen={(value) => {
          this.props.setModalSelectAddressState(value);
        }}
        displayCloseIcon={false}
      >
        <div className="UCP_ModalSelectAddress" style={{ padding: 0 }}>
          {list_address.length > 0 ? (
            list_address.map((address_item, index) => {
              if (index > 5) return null;
              return (
                <div className="USA_Address">
                  <div className="USA_Address_Name">{address_item.name}</div>
                  <div className="USA_Address_RowInfo">
                    <span className="USA_Address_Label">Địa chỉ:</span>
                    {`${address_item.fullAddress}, ${address_item.wardName}, ${address_item.districtName}, ${address_item.provinceName}`}
                  </div>
                  <div className="USA_Address_RowInfo">
                    <span className="USA_Address_Label">Điện thoại:</span>
                    {address_item.phone}
                  </div>
                  <div className="USA_Address_Action">
                    <div
                      onClick={() => {
                        this.props.setModalSelectAddressState(false);
                        this.props.selectAddress(address_item);
                        onSelectAddress?.(address_item);
                      }}
                      className="USA_Action_Edit"
                    >
                      Chọn
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>Người dùng này chưa có địa chỉ giao hàng nào</div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.admin_create_order;

  return {
    modalSelectAddressState: _state.modalSelectAddressState,
    list_address: _state.list_address,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModalSelectAddressState: (state) => {
      dispatch(
        AdminCreateOrderActions.action.setModalSelectAddressState(state)
      );
    },
    selectAddress: (address = {}) => {
      dispatch(AdminCreateOrderActions.action.selectAddress(address));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelectAddress);
