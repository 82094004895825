import { Button, Col, Input, Pagination, Row, Select, Table } from "antd";
import React, { PureComponent } from "react";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import colors from "../../../../const/colors";
import router_links from "../../../../const/router_link";
import {
  convertIsoDateToString,
  numberDisplayThousand,
} from "../../../../services/utils/helper";
import {
  getTypeWareHouseStatus,
  WareHouseStatusArr,
} from "../../../../types/warehouse.type";
import { history } from "../../../redux/store";
import { apiGetAllWareHouse } from "../warehouse-create/service/warehouse-create.sevice";
import "./index.scss";

const ITEM_LOAD = 8;

export default class WareHouesScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      loading: true,
    };
  }

  totalData = 0;

  filters = undefined;

  componentDidMount() {
    this.onGetData(1);
  }

  onGetData = async (pageIndex = 1) => {
    const { wareHouse, total } = await apiGetAllWareHouse({
      ...this.filters,
      pageIndex,
      pageSize: ITEM_LOAD,
    });

    this.totalData = total;
    this.setState({ page: pageIndex, data: wareHouse, loading: false });
  };

  onResetFilter = () => {
    this.filters = undefined;
    this.onGetData(1);
  };

  onChangePage = (page) => {
    this.onGetData(page);
  };

  onAcceptFilter = () => {
    this.setState({ loading: true }, () => {
      this.onGetData(1);
    });
  };

  renderColumns = [
    {
      title: "Mã kho hàng",
      key: "code",
      render: ({ code }) => {
        return <span>{code}</span>;
      },
    },
    {
      title: "Chủ kho hàng",
      key: "userInfo",
      render: ({ userInfo }) => {
        return (
          <>
            <span>{userInfo.fullName}</span>
            <br />
            <span>{userInfo.email}</span>
          </>
        );
      },
    },
    {
      title: "SL",
      key: "productIds",
      render: ({ productIds }) => {
        return <span>{productIds.length} SP</span>;
      },
    },
    {
      title: "Giá gốc",
      key: "priceProduct",
      align: "right",
      render: ({ priceProduct }) => {
        return (
          <div>
            <span>{numberDisplayThousand(priceProduct || 0)}đ</span>
          </div>
        );
      },
    },
    {
      title: "Chiết khấu",
      key: "priceDiscountProduct",
      align: "right",
      render: (e) => {
        return (
          <div>
            <span>
              {numberDisplayThousand(
                (e.priceProduct || 0) - (e.priceWholesale || 0)
              )}
              đ
            </span>
          </div>
        );
      },
    },
    {
      title: "Giá nhập",
      key: "priceWholesale",
      align: "right",
      render: ({ priceWholesale }) => {
        return <span>{numberDisplayThousand(priceWholesale)}đ</span>;
      },
    },
    {
      title: "Ngày tạo",
      key: "createdAt",
      align: "center",
      render: ({ createdAt }) => {
        return <span>{createdAt && convertIsoDateToString(createdAt)}</span>;
      },
    },
    {
      title: "Trạng thái",
      key: "status",
      render: ({ status }) => {
        return (
          <span
            style={{
              color: getTypeWareHouseStatus(status).color,
            }}
          >
            {getTypeWareHouseStatus(status).name}
          </span>
        );
      },
    },
    {
      title: "Hành động",
      key: "_id",
      width: 100,
      render: ({ _id }) => {
        return (
          <>
            <Link
              className="APL_Table_Action"
              style={{
                backgroundColor: "#074f51",
                color: "white",
              }}
              to={`${router_links.A_WARE_HOUSE_CREATE}?${_id}`}
            >
              <FaEdit className="APL_Table_Icon" />
              <span className="superMiniHide">Sửa</span>
            </Link>
          </>
        );
      },
    },
  ];

  render() {
    const { data, page, loading } = this.state;

    return (
      <div className="warehouse">
        <div className="header">
          <Button
            name="Tạo kho hàng"
            type="primary"
            className="ml-2"
            style={{
              backgroundColor: colors.primaryColor,
              borderColor: colors.primaryColor,
            }}
            onClick={() => {
              history.push(router_links.A_WARE_HOUSE_CREATE);
            }}
          >
            Tạo kho hàng
          </Button>
          <div style={{ flex: 1 }} />
          <Button
            type="primary"
            className="ml-2"
            style={{ backgroundColor: "#a04087", borderColor: "#a04087" }}
          >
            {this.totalData} kho hàng
          </Button>

          <div className="item-header">
            <Input
              placeholder="Mã KH/Tên/SDT/Email"
              allowClear
              onChange={(e) => {
                this.filters = {
                  ...this.filters,
                  keyword: e.target.value,
                };
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.onAcceptFilter();
                }
              }}
            />
          </div>

          <div className="item-header">
            <Select
              placeholder="Trạng kho hàng"
              style={{ width: 160 }}
              defaultValue={this.statusWareHouse}
              allowClear
              onChange={(e) => {
                this.filters = {
                  ...this.filters,
                  status: e,
                };
              }}
            >
              {WareHouseStatusArr.map((e) => (
                <Select.Option value={e}>
                  {getTypeWareHouseStatus(e).name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <Button
            type="primary"
            danger
            className="item-header"
            onClick={() => {
              window.location.reload();
            }}
          >
            Bỏ lọc
          </Button>

          <Button
            type="primary"
            className="item-header"
            loading={loading}
            onClick={this.onAcceptFilter}
          >
            Tìm kiếm
          </Button>
        </div>

        <Row>
          <Col span={24}>
            <Table
              loading={loading}
              size="middle"
              key={1}
              dataSource={data || []}
              columns={this.renderColumns}
              pagination={false}
              scroll={{ x: "auto" }}
              rowKey="_id"
            />
          </Col>

          <Col
            span={24}
            style={{
              display: "inline-flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <div className="d-flex justify-content-end pagination">
              <Pagination
                size="small"
                showSizeChanger={false}
                total={this.totalData}
                showTotal={(total, range) => `${range[0]}-${range[1]}/${total}`}
                current={page}
                defaultPageSize={ITEM_LOAD}
                onChange={this.onChangePage}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
