import React, { Component } from "react";
// icon
import {
  FaEdit,
  FaLock,
  FaPlusCircle,
  FaSearch,
  FaUnlock,
} from "react-icons/fa";
// redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import EmptySearch from "../../../../components/empty_search";
import ModalConfirmAction from "../../../../components/modal/modal_confirm_action";
// component
import Pagination from "../../../../components/paginations/paginations";
import router_links from "../../../../const/router_link";
import { formatToYMDHM } from "../../../../services/utils/helper";
import AdminVoucherActions from "../../../redux/admin_voucher/actions";
// scss
import "./index.scss";

const list_flexs = [5, 15, 25, 15, 25, 15, 25];

class AdminListCouponOfCustomerGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 30,
      pageSize: 10,
      pageIndex: 1,
    };
  }

  // life circle
  componentDidMount = () => {
    this.props.init();
  };

  // render
  renderActionBar = () => {
    return (
      <div className="APL_FilterPanel">
        <div className="APL_FilterPanel">
          <Link
            className="APL_ButtonAdd"
            to={`${router_links.A_CREATE_VOUCHER}`}
          >
            <FaPlusCircle className="APL_ButtonAddIcon" />
            Thêm mã khuyến mại
          </Link>
        </div>

        <div className="FilterSearchAndSort">
          <div className="APL_SearchBoxPanel">
            <input
              type="text"
              placeholder="Tìm kiếm"
              aria-label="Tim kiem"
              name="keyword"
              value={this.props.textSearch}
              onChange={(e) => {
                this.props.updateTextSearch(e.target.value);
              }}
              className="APL_SearchBox"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.searchFlag = true;
                  this.props.searchVoucher(this.props.textSearch);
                }
              }}
            />
            <FaSearch
              className="APL_SearchBoxIcon"
              onClick={() => {
                this.searchFlag = true;
                this.props.searchVoucher(this.props.textSearch);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderListVoucher = () => {
    let { pageSize, total, pageIndex, list_vouchers } = this.props;

    if (
      (Array.isArray(list_vouchers) && list_vouchers.length) ||
      !this.searchFlag
    ) {
      return (
        <table className="CDS_Table" style={{}}>
          <tr className="CDS_TableRowHeader">
            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[0],
                marginRight: 10,
              }}
            >
              STT
            </th>
            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[1],
              }}
            >
              Mã khuyến mại
            </th>
            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[2],
              }}
            >
              Mô tả
            </th>

            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[3],
              }}
            >
              Giảm giá (%)
            </th>

            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[4],
              }}
            >
              Thời gian
            </th>

            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[5],
              }}
            >
              Đối tượng
            </th>

            <th className="CDS_HeaderText" style={{ flex: list_flexs[6] }}>
              Hành động
            </th>
          </tr>
          {list_vouchers.map((entity, index) => {
            return (
              <tr
                className="CDS_TableRow"
                style={{
                  borderBottom:
                    index === list_vouchers.length - 1
                      ? "none"
                      : "1px solid #e2e2e2",
                }}
              >
                <td
                  className="CDS_TableCell"
                  style={{
                    flex: list_flexs[0],
                    marginRight: 10,
                  }}
                >
                  {index + 1 + pageSize * (pageIndex - 1)}
                </td>
                <td
                  className="CDS_TableCell"
                  style={{
                    flex: list_flexs[1],
                  }}
                >
                  {entity.code}
                </td>
                <td
                  className="CDS_TableCell superMiniHide"
                  style={{ flex: list_flexs[2] }}
                >
                  {entity.description}
                </td>

                <td
                  className="CDS_TableCell"
                  style={{
                    flex: list_flexs[3],
                  }}
                >
                  {`${entity.discount}%`}
                </td>

                <td
                  className="CDS_TableCell"
                  style={{
                    flex: list_flexs[4],
                  }}
                >
                  {formatToYMDHM(entity.startDate)}
                  <br />
                  ------------------------
                  <br />
                  {formatToYMDHM(entity.endDate)}
                </td>

                <td
                  className="CDS_TableCell"
                  style={{
                    flex: list_flexs[5],
                  }}
                >
                  {entity.productIds.length > 0
                    ? "Theo từng sản phẩm"
                    : "Tất cả các sản phẩm"}
                </td>

                <td
                  className="CDS_TableCell"
                  style={{
                    display: "flex",
                    flex: list_flexs[6],
                  }}
                >
                  <Link
                    className="APL_Table_Action"
                    style={{
                      backgroundColor: "#074f51",
                      color: "white",
                    }}
                    to={`${router_links.A_CREATE_VOUCHER}?${entity._id}`}
                  >
                    <FaEdit className="APL_Table_Icon" />
                    <span className="superMiniHide">Sửa</span>
                  </Link>

                  {entity.status ? (
                    <div
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        width: 120,
                      }}
                      onClick={() => {
                        this.setState({
                          modalConfirmDeleteState: true,
                        });
                        this.props.setCurrentVoucher(entity);
                      }}
                    >
                      <FaLock className="APL_Table_Icon" />
                      <span className="superMiniHide">Khoá</span>
                    </div>
                  ) : (
                    <div
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: "orange",
                        color: "white",
                        width: 120,
                      }}
                      onClick={() => {
                        this.setState({
                          modalConfirmDeleteState: true,
                        });
                        this.props.setCurrentVoucher(entity);
                      }}
                    >
                      <FaUnlock className="APL_Table_Icon" />
                      <span className="superMiniHide">Kích hoạt</span>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </table>
      );
    } else {
      return <EmptySearch />;
    }
  };

  render() {
    let { pageSize, total, pageIndex, mdCurrentVoucherState } = this.props;
    let currentEntity = this.props.current_voucher;

    return (
      <div className="AdminListCouponOfCustomerGroup">
        {this.renderActionBar()}

        {this.renderListVoucher()}

        <div>
          <Pagination
            onChange={(index) => {
              this.props.updatePageIndex(index);
            }}
            pageSize={pageSize}
            pageIndex={pageIndex}
            total={total}
          />
        </div>

        <ModalConfirmAction
          isOpen={this.state.modalConfirmDeleteState}
          setIsOpen={(value) =>
            this.setState({ modalConfirmDeleteState: value })
          }
          onOk={() => {
            this.setState({ modalConfirmDeleteState: false });
            if (currentEntity.status)
              this.props.deactiveVoucer(currentEntity._id);
            else this.props.activeVoucher(currentEntity._id);
          }}
          onCancel={() => this.setState({ modalConfirmDeleteState: false })}
          text={
            currentEntity.status
              ? "Bạn có chắc chắn khoá mã khuyến mại này?"
              : "Bạn có chắc chắn kích hoạt mã khuyến mại này?"
          }
          displayCloseIcon={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.admin_voucher;

  return {
    pageSize: _state.pageSize,
    pageIndex: _state.pageIndex,
    total: _state.total,

    textSearch: _state.textSearch,
    list_vouchers: _state.list_vouchers,
    current_voucher: _state.current_voucher,
    mdCurrentVoucherState: _state.mdCurrentVoucherState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(AdminVoucherActions.action.loadListVoucher());
    },

    setCurrentVoucher: (voucher) => {
      dispatch(AdminVoucherActions.action.setCurrentVoucher(voucher));
    },
    updateTextSearch: (textSearch) => {
      dispatch(AdminVoucherActions.action.updateTextSearch(textSearch));
    },
    searchVoucher: () => {
      dispatch(AdminVoucherActions.action.searchVoucher());
    },
    updatePageIndex: (pageIndex) => {
      dispatch(AdminVoucherActions.action.updatePageIndex(pageIndex));
    },

    activeVoucher: (id) => {
      dispatch(AdminVoucherActions.action.activeVoucher(id));
    },
    deactiveVoucer: (id) => {
      dispatch(AdminVoucherActions.action.deactiveVoucher(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminListCouponOfCustomerGroup);
