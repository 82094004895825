import { Switch } from "antd";
import React, { Component } from "react";
// icon
import {
  FaEdit,
  FaLock,
  FaPlusCircle,
  FaSave,
  FaSearch,
  FaTimes,
  FaUnlock
} from "react-icons/fa";
// redux
import { connect } from "react-redux";
import Modal from "../../../../components/modal";
import ModalConfirmAction from "../../../../components/modal/modal_confirm_action";
// component
import Pagination from "../../../../components/paginations/paginations";
import module_app from "../../../../const/module_app";
import {
  default as permission_action,
  default as PermissonActionType
} from "../../../../const/permission_action";
import AdminGroupUserActions from "../../../redux/admin_group_user/actions";
import { paramsPermisson } from "../../../routers/admin_site";
import AdminCheckPermissionComponent from "../components/admin-check-permission";
import "./index.scss";
import ModalAddUserGroup from "./modal_add_user_group";

const default_entity = {
  name: "",
  description: "",
  status: true,
  isAdmin: false,
};

const list_flexs = [5, 15, 8, 20, 8, 20, 8, 10, 8, 15];
class ListUserGroupScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      total: 30,

      currentEntity: default_entity,

      modalConfirmDeleteState: false,
      modalDetailGroupUserState: false,
    };
  }

  // life cycle
  componentDidMount() {
    if (typeof this.props.init === "function") {
      this.props.init();
    }
  }

  // handle
  onChangeTextSearch = (evt) => {
    const textSearch = evt.target.value;
    if (typeof this.props.updateTextSearch === "function") {
      this.props.updateTextSearch(textSearch);
    }
  };

  onClickButtonEdit = (group) => {
    this.props.setCurrentGroup(group);
    this.setState({
      modalDetailGroupUserState: true,
    });
    if (typeof this.props.loadListPermissionOfGroup === "function") {
      this.props.loadListPermissionOfGroup(group._id);
    }
  };

  onClickButtonAddUserGroup = () => {
    if (typeof this.props.setModalUserGroupState === "function") {
      this.props.setModalUserGroupState(true);
    }
  };

  // helper
  _getTextOfPermissionAction = (action) => {
    switch (action) {
      case PermissonActionType.INSERT:
        return "Thêm";

      case PermissonActionType.VIEW:
        return "Xem";

      case PermissonActionType.UPDATE:
        return "Sửa";

      case PermissonActionType.REMOVE:
        return "Xoá";

      default:
        return "";
    }
  };

  // render
  _renderDetailGroupUser = () => {
    const { permissions, current_group } = this.props;
    return (
      <div className="Admin_ModalDetailGroupUser">
        <div className="AMDG_Title">CHI TIẾT NHÓM NGƯỜI DÙNG</div>

        <div className="AMDG_RowInfo">
          <div className="AMDG_RowInfo_Title">Tên</div>
          <div className="AMDG_RowInfo_Content">
            <input
              className="AMDG_RowInfo_Input"
              value={current_group.name}
              onChange={(e) => {
                this.props.updateCurrentGroup({
                  name: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="AMDG_RowInfo">
          <div className="AMDG_RowInfo_Title">Mô tả</div>
          <div className="AMDG_RowInfo_Content">
            <textarea
              style={{
                minHeight: 60,
              }}
              className="AMDG_RowInfo_Input"
              value={current_group.description}
              onChange={(e) => {
                this.props.updateCurrentGroup({
                  description: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="AMDG_RowInfo">
          <div className="AMDG_RowInfo_Titlez">Phân quyền</div>
        </div>

        {permissions.map((entity, index) => {
          return (
            <div className="AMDG_RowInfo" key={index}>
              <div className="AMDG_RowInfo_Title_Small">
                {entity.moduleName}
              </div>
              <div className="AMDG_RowInfo_Content">
                {entity.permissions.map((permission, i) => {
                  return (
                    <div className="AMDG_Permission">
                      <Switch
                        checked={permission.isAllow}
                        onChange={(value) => {
                          this.props.updatePermission(permission._id, value);
                        }}
                      />
                      <div className="AMDG_Permission_Title">
                        {this._getTextOfPermissionAction(permission.action)}
                      </div>
                    </div>
                  );
                })}
                {/* <div className="AMDG_Permission">
                                    <Switch defaultChecked />
                                    <div className="AMDG_Permission_Title">
                                        Xem
                                    </div>
                                </div>
                                <div className="AMDG_Permission">
                                    <Switch />
                                    <div className="AMDG_Permission_Title">
                                        Thêm
                                    </div>
                                </div>
                                <div className="AMDG_Permission">
                                    <Switch />
                                    <div className="AMDG_Permission_Title">
                                        Sửa
                                    </div>
                                </div>
                                <div className="AMDG_Permission">
                                    <Switch />
                                    <div className="AMDG_Permission_Title">
                                        Xóa
                                    </div>
                                </div> */}
              </div>
            </div>
          );
        })}

        <div className="AMDG_RowInfo_Action">
          <div
            className="AMDG_Buttonz"
            onClick={() => {
              this.props.saveCurrentGroup(current_group);
              this.setState({ modalDetailGroupUserState: false });
            }}
          >
            <FaSave className="AMDG_Buttonz_Icon" />
            Lưu
          </div>
          <div
            className="AMDG_Buttonz"
            onClick={() => this.setState({ modalDetailGroupUserState: false })}
            style={{
              backgroundColor: "red",
            }}
          >
            <FaTimes className="AMDG_Buttonz_Icon" />
            Hủy
          </div>
        </div>
      </div>
    );
  };

  _renderListGroupUser = () => {
    // let list_group_user = list_group_user
    let { pageSize, pageIndex } = this.state;
    const { groups } = this.props;

    return (
      <table
        className="CDS_Table"
        style={{
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <tr className="CDS_TableRowHeader">
          <th
            className="CDS_HeaderText"
            style={{
              flex: list_flexs[0],
              marginRight: 10,
            }}
          >
            STT
          </th>
          <th
            className="CDS_HeaderText"
            style={{
              flex: list_flexs[1],
              justifyContent: "left",
            }}
          >
            Tên nhóm người dùng
          </th>
          <th
            className="CDS_HeaderText"
            style={{
              flex: list_flexs[2],
            }}
          >
            Trạng thái
          </th>
          <th
            className="CDS_HeaderText"
            style={{
              flex: list_flexs[3],
            }}
          >
            Mô tả
          </th>
          <th className="CDS_HeaderText" style={{ flex: list_flexs[4] }}>
            Hành động
          </th>
        </tr>
        {groups.map((entity, index) => {
          return (
            <tr
              className="CDS_TableRow"
              style={{
                borderBottom:
                  index === groups.length - 1 ? "none" : "1px solid #e2e2e2",
              }}
            >
              <td
                className="CDS_TableCell"
                style={{
                  flex: list_flexs[0],
                  // justifyContent: "left",
                  marginRight: 10,
                }}
              >
                {index + 1 + pageSize * (pageIndex - 1)}
              </td>
              <td
                className="CDS_TableCell"
                style={{
                  flex: list_flexs[1],
                  justifyContent: "left",
                }}
              >
                {entity.name}
              </td>

              <td
                className="CDS_TableCell"
                style={{
                  flex: list_flexs[2],
                  color: entity.status ? "green" : "red",
                  fontWeight: 600,
                }}
              >
                {entity.status ? "Đang kích hoạt" : "Đang bị khóa"}
              </td>

              <td
                className="CDS_TableCell superMiniHide"
                style={{ flex: list_flexs[3] }}
              >
                {entity.description}
              </td>

              <td className="CDS_TableCell" style={{ flex: list_flexs[4] }}>
                <AdminCheckPermissionComponent
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_GROUP_CUSTOMER_MANAGEMENT,
                    permission_action.UPDATE
                  )}
                  noContent
                >
                  <div
                    // to={`${Router_Links.A_DETAIL_USER}?id=${user._id}`}
                    className="APL_Table_Action"
                    style={{
                      backgroundColor: "#074f51",
                      color: "white",
                    }}
                    onClick={() => this.onClickButtonEdit(entity)}
                  >
                    <FaEdit className="APL_Table_Icon" />
                    <span className="superMiniHide">Sửa</span>
                  </div>
                </AdminCheckPermissionComponent>

                <AdminCheckPermissionComponent
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_GROUP_CUSTOMER_MANAGEMENT,
                    permission_action.REMOVE
                  )}
                  noContent
                >
                  {entity.status ? (
                    <div
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        width: 120,
                      }}
                      onClick={() => {
                        this.setState({
                          currentEntity: entity,
                          modalConfirmDeleteState: true,
                        });
                      }}
                    >
                      <FaLock className="APL_Table_Icon" />
                      <span className="superMiniHide">Khoá</span>
                    </div>
                  ) : (
                    <div
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: "#074f51",
                        color: "white",
                        width: 120,
                      }}
                      onClick={() => {
                        this.setState({
                          currentEntity: entity,
                          modalConfirmDeleteState: true,
                        });
                      }}
                    >
                      <FaUnlock className="APL_Table_Icon" />
                      <span className="superMiniHide">Kích hoạt</span>
                    </div>
                  )}
                </AdminCheckPermissionComponent>
              </td>
            </tr>
          );
        })}
      </table>
    );
  };

  render() {
    let { pageSize, total, pageIndex, textSearch } = this.props;
    let { currentEntity } = this.state;

    return (
      <div className="AdminListUserGroupScreen">
        <div className="APL_FilterPanel">
          <div className="APL_FilterPanel">
            <AdminCheckPermissionComponent
              paramsPermission={paramsPermisson(
                module_app.MODULE_GROUP_CUSTOMER_MANAGEMENT,
                permission_action.INSERT
              )}
              noContent
            >
              <div
                className="APL_ButtonAdd"
                onClick={this.onClickButtonAddUserGroup}
              >
                <FaPlusCircle className="APL_ButtonAddIcon" />
                Thêm nhóm người dùng
              </div>
            </AdminCheckPermissionComponent>
          </div>

          <div className="FilterSearchAndSort">
            <div className="APL_SearchBoxPanel">
              <input
                type="text"
                placeholder="Tìm kiếm"
                aria-label="Tim kiem"
                name="keyword"
                value={textSearch}
                onChange={this.onChangeTextSearch}
                className="APL_SearchBox"
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.props.searchUserGroup(this.props.textSearch);
                  }
                }}
              />
              <FaSearch
                className="APL_SearchBoxIcon"
                onClick={() => {
                  this.props.searchUserGroup(this.props.textSearch);
                }}
              />
            </div>
          </div>
        </div>

        {
          // render list user
          this._renderListGroupUser()
        }

        <div className="UHB_PaginateSection">
          <Pagination
            onChange={(index) => {
              this.props.updatePageIndex(index);
            }}
            pageSize={pageSize}
            pageIndex={pageIndex}
            total={total}
          />
        </div>

        <Modal
          isOpen={this.state.modalDetailGroupUserState}
          setIsOpen={(value) => {
            this.setState({
              modalDetailGroupUserState: value,
            });
          }}
          displayCloseIcon={false}
        >
          {this._renderDetailGroupUser()}
        </Modal>

        <ModalConfirmAction
          isOpen={this.state.modalConfirmDeleteState}
          setIsOpen={(value) =>
            this.setState({ modalConfirmDeleteState: value })
          }
          onOk={() => {
            this.setState({ modalConfirmDeleteState: false });

            if (currentEntity.status)
              this.props.inActiveGroupUser(currentEntity._id);
            else this.props.acticveGroupUser(currentEntity._id);
          }}
          onCancel={() => this.setState({ modalConfirmDeleteState: false })}
          text={
            currentEntity.status
              ? "Bạn có chắc chắn khoá nhóm người dùng này?"
              : "Bạn có chắc chắn kích hoạt nhóm người dùng này?"
          }
          displayCloseIcon={true}
        />

        <ModalAddUserGroup />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.admin_group_user;
  return {
    groups: _state.groups,
    pageSize: _state.pageSize,
    total: _state.total,
    pageIndex: _state.pageIndex,
    textSearch: _state.textSearch,
    permissions: _state.permissions,

    current_group: _state.current_group,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(AdminGroupUserActions.action.loadListGroupUser());
    },
    updatePageIndex: (pageIndex) => {
      dispatch(AdminGroupUserActions.action.updatePageIndex(pageIndex));
    },
    searchUserGroup: (textSearch) => {
      dispatch(AdminGroupUserActions.action.searchGroup(textSearch));
    },
    updateTextSearch: (textSearch) => {
      dispatch(AdminGroupUserActions.action.updateTextSearch(textSearch));
    },

    // permission
    loadListPermissionOfGroup: (groupId) => {
      dispatch(AdminGroupUserActions.action.loadListPermissionOfGroup(groupId));
    },
    updatePermission: (id = "", status = false) => {
      dispatch(AdminGroupUserActions.action.updatePermission(id, status));
    },

    setModalUserGroupState: (state) => {
      dispatch(AdminGroupUserActions.action.setModalUserGroupState(state));
    },

    acticveGroupUser: (id = "") => {
      dispatch(AdminGroupUserActions.action.activeGroup(id));
    },
    inActiveGroupUser: (id = "") => {
      dispatch(AdminGroupUserActions.action.inActiveGroup(id));
    },

    // current group
    setCurrentGroup: (group = {}) => {
      dispatch(AdminGroupUserActions.action.setCurrentGroup(group));
    },
    updateCurrentGroup: (group = {}) => {
      dispatch(AdminGroupUserActions.action.updateCurrentGroup(group));
    },
    saveCurrentGroup: (group = { _id: "" }) => {
      dispatch(AdminGroupUserActions.action.saveCurrentGroup(group._id, group));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListUserGroupScreen);
