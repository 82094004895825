import React, { Component } from "react";
import { FaPlus } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { productUnit } from "../../../../../const/product_unit";
import { regexDecimal, regexNumber } from "../../../../../const/regex_consts";
import Router_Links from "../../../../../const/router_link";
import { getImageUrl } from "../../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../../services/utils/helper";
import AdminCreateOrderActions from "../../../../redux/admin_create_order/actions";

class ListProducts extends Component {
  onChangeQuantity = (evt, cartItem) => {
    const value = evt.target.value;
    const isNumber = regexNumber.test(value);
    const isDecimal = regexDecimal.test(value);
    const isWholePart = /^\d+(\.)$/.test(value);

    if (
      cartItem.product.productUnit.name === productUnit.KG &&
      (isNumber || isDecimal || isWholePart)
    ) {
      this.props.updateCartItem(cartItem._id, value);
    } else if (isNumber) {
      this.props.updateCartItem(cartItem._id, value);
    }
  };

  renderProduct = (cart_item, index, total_items) => {
    let product = cart_item.product ? cart_item.product : { thumbnail: "" };
    let _total = cart_item.quantity * cart_item.price;
    let unit_name = product.productUnit ? product.productUnit.description : "";

    return (
      <div
        className="UCP_CartItem"
        style={{
          borderBottom:
            index === total_items - 1 ? "none" : "1px solid #e2e2e2",
        }}
      >
        <Link to={`${Router_Links.U_DETAIL_PRODUCT}/${product._id}`}>
          <img
            className="UCP_CartItem_Image"
            alt="Image"
            src={getImageUrl(product.thumbnail)}
          />
        </Link>
        <div className="UCP_CartItem_NamePanel">
          <Link
            to={`${Router_Links.U_DETAIL_PRODUCT}/${product._id}`}
            className="UCP_CartItem_Name"
          >
            {product.name}
          </Link>
          <div className="UCP_CartItem_Price">
            {numberDisplayThousand(cart_item.price)} đ/ {unit_name}
          </div>
          <div className="UCP_CartItem_ActionPanel">
            <div
              className="UCP_CartItem_Action"
              onClick={() => {
                this.props.removeCartDetail(cart_item._id);
              }}
            >
              Xoá
            </div>
          </div>
        </div>
        <div className="UCP_CartItem_Total">
          {numberDisplayThousand(_total)}đ
        </div>
        <div className="UCP_CartItem_Quantity">
          <div
            className="UCP_CartItem_ButtonDown"
            onClick={() => {
              if (cart_item.quantity > 1)
                this.props.updateCartItem(
                  cart_item._id,
                  cart_item.quantity - 1
                );
              else this.props.removeCartDetail(cart_item._id);
            }}
          >
            -
          </div>

          <input
            value={cart_item.quantity}
            className="UCP_CartItem_QuantityTextBox"
            onChange={(evt) => this.onChangeQuantity(evt, cart_item)}
          />

          <div
            className="UCP_CartItem_ButtonUp"
            onClick={() => {
              this.props.updateCartItem(cart_item._id, cart_item.quantity + 1);
            }}
          >
            +
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { products, total } = this.props;

    return (
      <div
        className="COP_ListProducts"
        style={{
          padding: 15,
        }}
      >
        {products.map((product, index) => {
          return this.renderProduct(product, index, products.length);
        })}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="COP_Action_Btn"
            style={{
              backgroundColor: "white",
            }}
            onClick={() => {
              this.props.setModalSelectProductState(true);
            }}
          >
            <FaPlus className="COP_Action_Icon" />
            Thêm sản phẩm
          </div>

          <div className="COP_Total">
            {`Tạm tính: ${numberDisplayThousand(total)}đ`}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.admin_create_order;

  return {
    products: _state.products,
    total: _state.total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModalSelectProductState: (state) => {
      dispatch(
        AdminCreateOrderActions.action.setModalSelectProductState(state)
      );
    },
    removeCartDetail: (id) => {
      dispatch(AdminCreateOrderActions.action.removeProductItem(id));
    },
    updateCartItem: (id = "", quantity) => {
      dispatch(AdminCreateOrderActions.action.updateProductItem(id, quantity));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListProducts);
