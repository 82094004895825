/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import { dateFormatHMDYM, numberDisplayThousand } from "services/utils/helper";
import { apiGetInfoOrder } from "../service/list-order.service";
import "./print-bill.scss";

export default class PrintBillPosScreen extends Component {
  state = {
    orderInfo: undefined,
  };

  componentDidMount() {
    this.getInfoOder();
  }

  getInfoOder = async () => {
    const orderId = this.props.match.params.id
      ? this.props.match.params.id
      : this.props.orderId || "";
    const res = await apiGetInfoOrder(orderId);
    this.setState({ orderInfo: res || undefined }, this.onPrint);
  };

  onPrint = () => {
    setTimeout(() => {
      window.print();
    }, 500);
  };

  renderProduct = (orderDetails, orderInfo) => {
    let fee_shipping = orderInfo.shipping.shippingFee;
    fee_shipping = orderInfo.order.shippingFee || 0;

    return (
      <>
        <table className="d-table d-mt-10">
          <tr className="d-space d-pb-5">
            <th class="d-table-title d-text-left">
              <span class="td">{`Sản phẩm đã mua (${orderDetails.length} SP)`}</span>
            </th>
            <th class="d-table-title">
              <span class="td">SL</span>
            </th>
            <th class="d-table-title d-text-right">
              <span class="td">Đơn giá</span>
            </th>
            <th class="d-table-title d-text-right">
              <span class="td">Thành tiền</span>
            </th>
          </tr>
          <div className="d-mt-5" />
          {orderDetails?.map((e) => {
            return (
              <tr>
                <th class="d-table-title d-text-left">
                  <span class="td">{e.productName}</span>
                </th>
                <th class="d-table-title">
                  <span class="td">{e.quantity}</span>
                </th>
                <th class="d-table-title d-text-right">
                  <span class="td">
                    {`${numberDisplayThousand(e.price)}/${
                      e?.productUnit?.description || ""
                    }`}
                  </span>
                </th>
                <th class="d-table-title d-text-right">
                  <span class="td">
                    {numberDisplayThousand(e.price * e.quantity)}
                  </span>
                </th>
              </tr>
            );
          })}

          <div style={{ height: 5 }} />

          <tr className="d-space-top d-pb-5 d-pt-5">
            <td class="d-table-title longCol numberCell" colspan="3">
              <span className="td">Phí ship:</span>
            </td>
            <td class="d-table-title numberCellRight">
              <span className="td">{numberDisplayThousand(fee_shipping)}</span>
            </td>
          </tr>

          <tr>
            <td class="d-table-title longCol numberCell" colspan="3">
              <span className="td">Giảm giá:</span>
            </td>
            <td class="d-table-title numberCellRight">
              <span className="td">
                {numberDisplayThousand(orderInfo.order?.totalPriceDiscount)}
              </span>
            </td>
          </tr>

          <tr>
            <td class="d-table-title longCol numberCell" colspan="3">
              <span className="td">Số điểm trừ:</span>
            </td>
            <td class="d-table-title numberCellRight">
              <span className="td">
                {numberDisplayThousand(orderInfo.order?.usedPoints)}
              </span>
            </td>
          </tr>

          <tr>
            <td class="d-table-title longCol numberCell" colspan="3">
              <span className="td">Thành tiền (VND):</span>
            </td>
            <td class="d-table-title numberCellRight">
              <span className="td">
                {numberDisplayThousand(
                  orderInfo.order.totalPrice + fee_shipping
                )}
              </span>
            </td>
          </tr>
        </table>
      </>
    );
  };

  renderBundledProduct = (products) => {
    if (products.length === 0) {
      return null;
    }

    return (
      <>
        <table className="d-table d-mt-5">
          <tr className="d-space d-pb-5">
            <th class="d-table-title d-text-left">
              <span class="td-2">{`Sản phẩm tặng kèm (${products.length} SP)`}</span>
            </th>
            <th class="d-table-title">
              <span class="td-2">SL</span>
            </th>
            <th class="d-table-title d-text-right">
              <span class="td-2">Đơn giá</span>
            </th>
          </tr>

          {products?.map((e) => {
            return (
              <tr>
                <th class="d-table-title d-text-left">
                  <span class="td-2">{e.name}</span>
                </th>
                <th class="d-table-title">
                  <span class="td-2">{e.qty}</span>
                </th>
                <th class="d-table-title d-text-right">
                  <span class="td-2">
                    {`${numberDisplayThousand(e.price)}/${
                      e?.productUnit?.description || ""
                    }`}
                  </span>
                </th>
              </tr>
            );
          })}
        </table>
        <div className="d-space d-mt-5" />
        <div className="d-space d-mt-5" />
      </>
    );
  };

  renderContent = () => {
    const { order, orderDetails, shipping } = this.state?.orderInfo || {};
    if (!order) {
      return null;
    }

    return (
      <>
        <div className="d-space d-mt-5" />
        <p className="d-des d-mt-10">{`Mã đơn hàng: ${order?.code}`}</p>
        <p className="d-des">
          {`Thời gian tạo: ${dateFormatHMDYM(order?.createdAt)}`}
        </p>
        <div className="d-space d-mt-5" />

        <p className="d-des d-mt-10">{`Người nhận: ${order?.userBuy?.fullName}`}</p>
        <p className="d-des">{`Điện thoại: ${order?.userBuy?.phone}`}</p>
        <p className="d-des">{`Số điểm được cộng từ đơn hàng: ${order.totalPointsFromOrder} điểm`}</p>
        <div className="d-space d-mt-5" />
        {this.renderBundledProduct(order?.bundledProducts || [])}
        {this.renderProduct(orderDetails, this.state.orderInfo)}

        <div className="d-space d-mt-5" />

        <p className="d-bottom">
          Cảm ơn quý khách đã tin tưởng ủng hộ dịch vụ của Cadosa để chăm sóc
          sức khỏe cho gia đình, Hãy cùng Cadosa lan tỏa giá trị tốt đẹp này
          bằng cách giới thiệu thêm ít nhận 03 người bạn tải app dưới mã giới
          thiệu của bạn để nhận thêm được nhiều ưu đãi từ Cadosa!
        </p>
      </>
    );
  };

  render() {
    return (
      <div className="d-container">
        <p className="d-des"></p>
        <p className="d-title">Hoá đơn bán hàng</p>
        <p className="d-des">Người gửi: Cadosa.vn</p>
        <p className="d-des">Điện thoại: 0966192629</p>
        <p className="d-des">
          Địa chỉ: 20 A, Ngõ 280 Đường Hồ Tùng Mậu, Mai Dịch, Cầu Giấy, Hà Nội
        </p>
        {this.renderContent()}
      </div>
    );
  }
}
