import PrintBillPosScreen from "App/screens/admin_views/list_order/order_detail_page/print-bill-pos";
import React, { Component } from "react";
import { connect } from "react-redux";
// route
import { Redirect, Route, Switch } from "react-router-dom";
import LoginRequireContainer from "../../components/login_require_component";
import PageNotFound from "../../components/specialPages/notFound";
import module_app from "../../const/module_app";
import permission_action from "../../const/permission_action";
import Router_Links from "../../const/router_link";
// redux
import authActions from "../redux/auth/actions";
import Category from "../screens/admin_views/category_list";
import CreateVoucherScreen from "../screens/admin_views/create-voucher";
import CustomerGroupPage from "../screens/admin_views/customer_group";
import HomePage from "../screens/admin_views/home_page";
import ListCategoryPostPage from "../screens/admin_views/list_category_post";
import ListPostPage from "../screens/admin_views/list_category_post/list_post_page";
import ListPostTagsPage from "../screens/admin_views/list_category_post/list_post_tags_page";
import DetaiPostPage from "../screens/admin_views/list_category_post/post_detail_page";
import EditPostPage from "../screens/admin_views/list_category_post/post_edit_page";
import ListCouponOfCustomerGroupPage from "../screens/admin_views/list_coupon_of_customer_group";
import ListFlashSale from "../screens/admin_views/list_flash_sale";
import AddProductFlashSalePage from "../screens/admin_views/list_flash_sale/add_product_flash_sale_page";
import FlashSaleDetail from "../screens/admin_views/list_flash_sale/flash_sale_detail_page";
import ListOrderPage from "../screens/admin_views/list_order";
import CreateOrderPage from "../screens/admin_views/list_order/create_order_page";
import DetailOrderPage from "../screens/admin_views/list_order/order_detail_page";
import ListUser from "../screens/admin_views/list_users";
import UserDetail from "../screens/admin_views/list_users_detail";
import ManagerUserGroup from "../screens/admin_views/list_user_group";
// component
import LoginPage from "../screens/admin_views/login_page";
import ManagerLevelRelationship from "../screens/admin_views/manager_level_relationship";
import ManagerListLevelUserPage from "../screens/admin_views/manager_list_level_user";
import NotifySystem from "../screens/admin_views/notify-system";
import ProductDetail from "../screens/admin_views/product_detail";
import ProductList from "../screens/admin_views/product_list";
// layout component
import SideBar from "../screens/admin_views/shared/sidebar";
import TopBar from "../screens/admin_views/shared/topbar";
import WareHouesScreen from "../screens/admin_views/warehouse";
import WareHouesCreateScreen from "../screens/admin_views/warehouse-create";
import WarehouseInsertProductScreen from "../screens/admin_views/warehouse-insert";
// scss
import "./admin_site.scss";

export const paramsPermisson = (module, action) => {
  return {
    module,
    action,
  };
};
class AdminSiteManagerView extends Component {
  renderContent = () => {
    const path = this.props.path;

    if (window.location.pathname.includes(Router_Links.A_PRINT_BILL_POS)) {
      return null;
    }

    return (
      <div className="MainAdminContent">
        <TopBar />

        <div className="AdContent">
          <SideBar />

          <div className="AdMainView">
            <div className="Card">
              <Switch>
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_HOME_PAGE}
                  component={HomePage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_DASHBOARD,
                    permission_action.VIEW
                  )}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_MANAGER_LIST_USER_GROUP}
                  component={ManagerUserGroup}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_GROUP_CUSTOMER_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_MANAGER_NOTIFY_SYSTEM}
                  component={NotifySystem}
                  redirect_link={Router_Links.A_LOGIN}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_MANAGER_PRODUCT}
                  component={ProductList}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_PRODUCT_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_DETAIL_PRODUCT}
                  component={ProductDetail}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_PRODUCT_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_LIST_ORDER}
                  component={ListOrderPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_ORDER_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_LIST_ORDER_DETAIl + "/:id"}
                  component={DetailOrderPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_ORDER_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_CREATE_ORDER}
                  component={CreateOrderPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_ORDER_MANAGEMENT,
                    permission_action.INSERT
                  )}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_LIST_WARE_HOUSE}
                  component={WareHouesScreen}
                  redirect_link={Router_Links.A_LOGIN}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_WARE_HOUSE_CREATE}
                  component={WareHouesCreateScreen}
                  redirect_link={Router_Links.A_LOGIN}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_WARE_HOUSE_INSERT_PRODUCT}
                  component={WarehouseInsertProductScreen}
                  redirect_link={Router_Links.A_LOGIN}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_CATEGORY}
                  component={Category}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_CATEGORY_PRODUCT_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_CREATE_PRODUCT}
                  component={ProductDetail}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_PRODUCT_MANAGEMENT,
                    permission_action.INSERT
                  )}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_LIST_USER}
                  component={ListUser}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_USER_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_DETAIL_USER}
                  component={UserDetail}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_USER_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_CREATE_USER}
                  component={UserDetail}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_USER_MANAGEMENT,
                    permission_action.INSERT
                  )}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_CUSTOMER_GROUP}
                  component={CustomerGroupPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_GROUP_CUSTOMER_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_CATEGORY_POST}
                  component={ListCategoryPostPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_CATEGORY_NEWS_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_LIST_POST}
                  component={ListPostPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_POST_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={`${Router_Links.A_EDIT_POST}/:id`}
                  component={EditPostPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_POST_MANAGEMENT,
                    permission_action.UPDATE
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={`${Router_Links.A_CREATE_POST}`}
                  component={EditPostPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_POST_MANAGEMENT,
                    permission_action.INSERT
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={`${Router_Links.A_DETAIL_POST}/:id`}
                  component={DetaiPostPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_POST_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />

                <LoginRequireContainer
                  exact
                  path={`${Router_Links.A_LIST_POST_TAGS}`}
                  component={ListPostTagsPage}
                  redirect_link={Router_Links.A_LOGIN}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_LIST_COUPON}
                  component={ListCouponOfCustomerGroupPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_VOUCHER_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_CREATE_VOUCHER}
                  component={CreateVoucherScreen}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_VOUCHER_MANAGEMENT,
                    permission_action.INSERT
                  )}
                />

                <LoginRequireContainer
                  exact
                  path={Router_Links.A_LEVEL_USER}
                  component={ManagerListLevelUserPage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_LEVEL_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                {/* <LoginRequireContainer
                exact
                path={Router_Links.A_REQUEST_CHANGE_CUSTOMER_GROUP}
                component={RequestChangeCustomerGroupPage}
                redirect_link={Router_Links.A_LOGIN}
              /> */}
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_LIST_FLASH_SALE}
                  component={ListFlashSale}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_FLASH_SALES_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_FLASH_SALE_DETAIL + "/:id"}
                  component={FlashSaleDetail}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_FLASH_SALES_MANAGEMENT,
                    permission_action.VIEW
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_FLASH_SALE_DETAIL_ADD_PRODUCT}
                  component={AddProductFlashSalePage}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_FLASH_SALES_MANAGEMENT,
                    permission_action.INSERT
                  )}
                />
                <LoginRequireContainer
                  exact
                  path={Router_Links.A_LEVEL_RELATIONSHIP}
                  component={ManagerLevelRelationship}
                  redirect_link={Router_Links.A_LOGIN}
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_LEVEL_RELATIONSHIP,
                    permission_action.VIEW
                  )}
                />

                <Switch>
                  <Route path={path + "/*"} component={PageNotFound} />
                </Switch>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const path = this.props.path;

    return (
      <Route path={path} onEnter={this.props.checkSession()}>
        <LoginRequireContainer
          exact
          path={Router_Links.A_PRINT_BILL_POS + "/:id"}
          component={PrintBillPosScreen}
          redirect_link={Router_Links.A_LOGIN}
        />

        {this.renderContent()}
      </Route>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkSession: () => {
      dispatch(authActions.action.checkSession());
    },
    checkSessionAdmin: () => {
      dispatch(authActions.action.checkSessionAdmin());
    },
  };
};

const AdminSiteManagerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSiteManagerView);

// admin site
class AdminSite extends Component {
  render() {
    const path = this.props.path;

    return (
      <Route path={path}>
        <Switch>
          <AdminSiteManagerContainer path={Router_Links.A_APP} />
          <Route exact path={Router_Links.A_LOGIN} component={LoginPage} />
          <Redirect to={Router_Links.A_LOGIN} />
        </Switch>
      </Route>
    );
  }
}

export default AdminSite;
