import { Button, Col, Row, Select, Table } from "antd";
import { truncate } from "lodash";
import React, { Component } from "react";
import {
  FaCheck,
  FaEdit,
  FaLock,
  FaPlusCircle,
  FaSearch,
  FaUnlock,
} from "react-icons/fa";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Pagination from "../../../../components/paginations/paginations";
import colors from "../../../../const/colors";
import Router_Links from "../../../../const/router_link";
import GroupCustomerApiServices from "../../../../services/api/customer_group";
import { getListLevel } from "../../../../services/api/level_user";
import { getImageUrl } from "../../../../services/api/upload";
import {
  convertIsoDateToString,
  numberDisplayThousand,
} from "../../../../services/utils/helper";
import UserActions from "../../../redux/admin_listuser/actions";
import "./index.scss";
import { apiGetListUser } from "./service/list-user.service";

const { Option } = Select;

const ITEM_LOAD = 8;

class AdminListUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalConfirmDeleteState: false,

      currentProduct: 0,
      levels: [],
      groups: [],

      data: [],
      page: 1,
      loading: true,
    };

    this.searchFlag = false;
  }

  totalData = 0;

  filters = undefined;

  componentDidMount = () => {
    this.onGetData(1);
    this.onGetDefaultValue();
  };

  onGetDefaultValue = async () => {
    const res = await getListLevel();
    this.setState({ levels: res.data || [] });

    const resGroup = await GroupCustomerApiServices.getListGroupCustomer(1, 50);
    this.setState({ groups: (resGroup.data && resGroup.data.items) || [] });
  };

  onGetData = async (pageIndex = 1) => {
    const res = await apiGetListUser({
      ...this.filters,
      pageIndex,
      pageSize: ITEM_LOAD,
    });

    this.totalData = res.total || 0;
    this.setState({ page: pageIndex, data: res.items || [], loading: false });
  };

  onResetFilter = () => {
    this.filters = undefined;
    this.onGetData(1);
  };

  onChangePage = (page) => {
    window.scrollTo(0, 0);
    this.setState({ loading: true }, () => {
      this.onGetData(page);
    });
  };

  onChangeLevel = (levelId) => {
    if (!levelId) {
      delete this.filters["filters[levelId]"];
      return;
    }

    this.filters = {
      ...this.filters,
      "filters[levelId]": levelId,
    };
  };

  onChangeGroup = (groupId) => {
    if (!groupId) {
      delete this.filters["filters[groupId]"];
      return;
    }

    this.filters = {
      ...this.filters,
      "filters[groupId]": groupId,
    };
  };

  onAcceptFilter = () => {
    this.setState({ loading: true }, () => {
      this.onGetData(1);
    });
  };

  renderColumns = [
    {
      title: "STT",
      key: "stt",
      align: "center",
      render: (e, j, index) => {
        const { page } = this.state;
        return (
          <span style={{ fontWeight: "600" }}>
            {index + 1 + ITEM_LOAD * (page - 1)}
          </span>
        );
      },
    },
    {
      title: "Thông tin người dùng",
      key: "fullName",
      width: 200,
      render: (user) => {
        return (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              flex: 1,
            }}
          >
            <img
              style={{
                height: 60,
                width: 60,
                objectFit: "cover",
                marginRight: 10,
                border: "1px solid #e8e8e8",
                borderRadius: 30,
              }}
              src={getImageUrl(user.avatar)}
              alt="avatar"
            />

            <div style={{ flex: 1 }}>
              <span style={{ fontWeight: "600" }}>{user.fullName}</span>
              {/* <br />
              Giới tính:{" "}
              <span style={{ fontWeight: "600" }}>
                {user.sex === 1 ? "Nam" : "Nữ"}
              </span> */}
              <br />
              SĐT: <span style={{ fontWeight: "600" }}>{user.phone}</span>
              <br />
              {user.email &&
                truncate(user.email, {
                  length: 25,
                })}
            </div>
            {user.approveGroupCustomer && <FaCheck className="dot-active" />}
          </div>
        );
      },
    },
    {
      title: "Cấp bậc",
      key: "level",
      align: "center",
      render: ({ level }) => {
        return <span>{level.description}</span>;
      },
    },
    {
      title: "Cadoxu",
      key: "cadoxu",
      align: "right",
      render: ({ cadoxu }) => {
        return (
          <span style={{ color: colors.primaryColor, fontWeight: "600" }}>
            {numberDisplayThousand(cadoxu)}
          </span>
        );
      },
    },
    {
      title: "Điểm thưởng còn lại",
      align: "right",
      key: "point",
      render: ({ point }) => {
        return (
          <span style={{ color: "orange", fontWeight: "600" }}>
            {numberDisplayThousand(point)}
          </span>
        );
      },
    },
    {
      title: "Điểm thưởng đã dùng",
      align: "right",
      key: "pointUsed",
      render: ({ pointUsed }) => {
        return (
          <span style={{ color: "orange", fontWeight: "600" }}>
            {numberDisplayThousand(pointUsed || 0)}
          </span>
        );
      },
    },
    // {
    //   title: "Hoa hồng",
    //   align: "right",
    //   key: "commission",
    //   render: ({ commission }) => {
    //     return (
    //       <span style={{ color: "#94702f", fontWeight: "600" }}>
    //         {numberDisplayThousand(commission || 0)} đ
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Hoa hồng HT",
    //   align: "right",
    //   key: "commissionSys",
    //   render: ({ commissionSys }) => {
    //     return (
    //       <span style={{ color: "#4642d6", fontWeight: "600" }}>
    //         {numberDisplayThousand(commissionSys || 0)} đ
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Ngày tham gia",
      align: "right",
      key: "createdAt",
      render: ({ createdAt }) => {
        return <span>{convertIsoDateToString(createdAt)}</span>;
      },
    },
    {
      title: "Hành động",
      key: "action",
      align: "center",
      render: (user) => {
        return (
          <div>
            <Link
              to={`${Router_Links.A_DETAIL_USER}?id=${user._id}`}
              className="APL_Table_Action"
              style={{
                backgroundColor: "#074f51",
                color: "white",
                marginBottom: 5,
              }}
            >
              <FaEdit className="APL_Table_Icon" />
              <span className="superMiniHide">Sửa</span>
            </Link>

            {user.isActive ? (
              <div
                onClick={() => {
                  this.props.blockUser(user._id);
                }}
                className="APL_Table_Action"
                style={{
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                <FaLock className="APL_Table_Icon" />
                <span className="superMiniHide">Khoá</span>
              </div>
            ) : (
              <div
                onClick={() => {
                  this.props.activeUser(user._id);
                }}
                className="APL_Table_Action"
                style={{
                  backgroundColor: "orange",
                  color: "white",
                }}
              >
                <FaUnlock className="APL_Table_Icon" />
                <span className="superMiniHide">Kích hoạt</span>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  render() {
    const { levels, groups, loading, page } = this.state;

    return (
      <div className="AdminListUsers">
        <div className="APL_FilterPanel">
          <div className="APL_FilterPanel">
            <div
              className="APL_ButtonAdd"
              onClick={() => {
                this.props.history.push(Router_Links.A_CREATE_USER);
              }}
            >
              <FaPlusCircle className="APL_ButtonAddIcon" />
              Thêm người dùng
            </div>
          </div>

          <div className="FilterSearchAndSort">
            <Button
              type="primary"
              className="mr-2"
              style={{ backgroundColor: "#a04087", borderColor: "#a04087" }}
            >
              {this.totalData} người dùng
            </Button>

            {/* <div className="dropdown2 mr-3">
              <Select
                placeholder="Nhóm người dùng"
                style={{ width: 180 }}
                allowClear
                onChange={this.onChangeGroup}
              >
                {groups.map((e) => (
                  <Option value={e._id}>{e.name}</Option>
                ))}
              </Select>
            </div> */}

            <div className="dropdown2 mr-3">
              <Select
                placeholder="Cấp bậc người dùng"
                style={{ width: 180 }}
                allowClear
                onChange={this.onChangeLevel}
              >
                {levels.map((e) => (
                  <Option value={e._id}>{e.name}</Option>
                ))}
              </Select>
            </div>
            <div className="APL_SearchBoxPanel">
              <input
                type="text"
                placeholder="Tìm kiếm"
                aria-label="Tim kiem"
                name="keyword"
                onChange={(e) => {
                  this.filters = {
                    ...this.filters,
                    keyword: e.target.value,
                  };
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.onAcceptFilter();
                  }
                }}
                className="APL_SearchBox"
              />
              <FaSearch
                className="APL_SearchBoxIcon"
                onClick={this.onAcceptFilter}
              />
            </div>

            <Button
              type="primary"
              danger
              className="ml-2"
              onClick={() => {
                window.location.reload();
              }}
            >
              Bỏ lọc
            </Button>

            <Button
              type="primary"
              className="ml-2"
              loading={loading}
              onClick={this.onAcceptFilter}
            >
              Tìm kiếm
            </Button>
          </div>
        </div>

        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <Table
              loading={loading}
              size="middle"
              key={1}
              dataSource={this.state.data || []}
              columns={this.renderColumns}
              pagination={false}
              scroll={{ x: "auto" }}
              rowKey="_id"
            />
          </Col>
        </Row>

        <div className="UHB_PaginateSection">
          <Pagination
            onChange={this.onChangePage}
            pageIndex={page}
            pageSize={ITEM_LOAD}
            total={this.totalData}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSortMode: (mode) => {
      dispatch(UserActions.action.updateSortMode(mode));
    },
    activeUser: (id) => {
      dispatch(UserActions.action.activeUser(id));
    },
    blockUser: (id) => {
      dispatch(UserActions.action.blockUser(id));
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(AdminListUsers));
