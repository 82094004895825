import { ConfigProvider, DatePicker, Input, Radio } from "antd";
import locale from "antd/lib/locale/vi_VN";
import moment from "moment";
import "moment/locale/vi";
import React, { PureComponent } from "react";
import { FaShippingFast } from "react-icons/fa";
import { connect } from "react-redux";
// router
import { Link } from "react-router-dom";
import RouterLinks from "../../../../../../const/router_link";
import ListShipping from "../../../../../../const/shipping_type";
import {
  getAhaShipPrice,
  OrderAdminApiServices,
} from "../../../../../../services/api/order";
import { getImageUrl } from "../../../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../../../services/utils/helper";
import NotificationsService from "../../../../../../services/utils/notifycationHelper";
import { OrderStatus } from "../../../../../../types/order.type";
import AdminCreateOrderActions from "../../../../../redux/admin_create_order/actions";
import ModalSelectProduct from "../../create_order_page/modal_select_product";
import "../index.scss";

const flexs = [25, 10, 10, 10];

class OrderDetailBottom extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      products: props.products || [],
      shipping_type:
        (props.order && props.order.serviceId) || ListShipping[0].type,
      shippingFee: (props.order && props.order.shippingFee) || 0,
    };
  }

  estimatedDeliveryTime =
    (this.props.order.estimatedDeliveryTime &&
      moment(this.props.order.estimatedDeliveryTime)) ||
    undefined;

  isEditOrder = !!(
    this.props.order.status === OrderStatus.PENDING ||
    this.props.order.status === OrderStatus.CONFIRM
  );

  getEstimatedDeliveryTime = () => {
    if (!this.estimatedDeliveryTime) return undefined;
    return moment(this.estimatedDeliveryTime, "YYYY/MM/DD").unix() * 1000;
  };

  onChangeQty = (index) => (e) => {
    let value = e.target.value;

    let products = [...this.state.products];

    products[index].quantity = value;

    this.setState({ products });
  };

  onChooseProduct = (product) => {
    let products = [...this.state.products];

    const params = {
      orderId: this.props.order._id,
      productId: product._id,
      quantity: 1,
      price: product.priceDiscount,
      // discount: product.price - product.priceDiscount,
      discount: 0,
      productName: product.name,
      isValueAddedProduct: product.isValueAddedProduct,
      thumbnail: product.thumbnail,
      point: product.point,
      productUnit: product.productUnit,
    };

    const index = products.findIndex((e) => e.productId === params.productId);
    if (index >= 0) {
      products[index].quantity = Number(products[index].quantity) + 1;
    } else {
      products = [...products, params];
    }

    this.setState({ products });
  };

  getShippingFee = async (addressId, serviceId) => {
    let shippingFee = this.state.shippingFee;

    if (serviceId !== "WARE_HOUSE") {
      const res = await getAhaShipPrice(addressId, serviceId);

      if (res.code === 200) {
        shippingFee = res.data.total_price || 0;
      } else {
        shippingFee = 0;
      }
    } else {
      shippingFee = 0;
    }
    this.setState({ shippingFee });
  };

  onChangeAddressShip = (e) => {
    const { order } = this.props;
    this.setState(
      {
        shipping_type: e.target.value,
      },
      () => {
        if (order.address && order.address._id) {
          this.getShippingFee(order.address._id, e.target.value);
        }
      }
    );
  };

  onUpdateOrder = async () => {
    // Cập nhật order
    const products = [...this.state.products];

    const newProducts = [];

    for (const product of products) {
      if (Number(product.quantity) > 0) {
        newProducts.push({
          ...product,
          quantity: Number(product.quantity),
        });
      }
    }

    const res = await OrderAdminApiServices.updateValueOrder(
      this.props.order._id,
      newProducts,
      this.state.shipping_type
    );

    if (res.code === 200) {
      window.location.reload();
      NotificationsService.success("Cập nhật đơn hàng thành công");
    } else {
      NotificationsService.success("Cập nhật đơn hàng thất bại");
    }
  };

  getTotalPrice() {
    let number = 0;
    const { products } = this.state;

    for (const product of products) {
      number += product.price * Number(product.quantity);
    }
    return number;
  }

  render() {
    const { products, shippingFee } = this.state;

    const { order } = this.props;

    const discountPrice = order.totalPriceDiscount
      ? order.totalPriceDiscount
      : 0;

    // let finalPrice = order.totalPrice ? order.totalPrice : 0;

    const usedPoints = order.usedPoints
      ? Number(Math.floor(order.usedPoints))
      : 0;

    let finalPrice = this.getTotalPrice() - discountPrice - usedPoints * 1000;

    const total = this.getTotalPrice();

    const shippingPrice = shippingFee;

    finalPrice = finalPrice + shippingPrice;

    if (finalPrice < 0) finalPrice = 0;

    return (
      <>
        <table className="DBP_ListItems">
          <tr className="DBP_Table_HeaderRow">
            <th
              className="DBP_Table_HeaderColumn"
              style={{
                justifyContent: "center",
                width: 70,
                marginRight: 10,
              }}
            >
              Sản phẩm
            </th>
            <th
              className="DBP_Table_HeaderColumn"
              style={{
                justifyContent: "flex-start",
                flex: flexs[0],
              }}
            >
              {/* Sản phẩm */}
            </th>
            <th className="DBP_Table_HeaderColumn" style={{ flex: flexs[1] }}>
              Giá
            </th>
            <th className="DBP_Table_HeaderColumn" style={{ flex: flexs[2] }}>
              Số lượng
            </th>
            <th
              className="DBP_Table_HeaderColumn"
              style={{
                justifyContent: "flex-end",
                flex: flexs[3],
              }}
            >
              Tạm tính
            </th>
          </tr>

          {products.map((item, index) => {
            const unit = item.productUnit ? item.productUnit : {};

            return (
              <tr
                className="DBP_TableRow"
                style={{
                  borderBottom:
                    index === products.length - 1
                      ? "none"
                      : "1px solid #e2e2e2",
                }}
              >
                <td
                  className="DBP_Table_Column"
                  style={{
                    justifyContent: "flex-start",
                    marginRight: 10,
                  }}
                >
                  <Link
                    to={`${RouterLinks.U_DETAIL_PRODUCT}/${item.productId}`}
                  >
                    <img
                      alt="image"
                      src={getImageUrl(item.thumbnail)}
                      className="DBP_Image_Product"
                    />
                  </Link>
                </td>
                <td
                  className="DBP_Table_Column"
                  style={{
                    justifyContent: "flex-start",
                    flex: flexs[0],
                  }}
                >
                  <Link
                    to={`${RouterLinks.U_DETAIL_PRODUCT}/${item.productId}`}
                    style={{
                      color: "black",
                    }}
                  >
                    {item.productName}
                  </Link>
                </td>
                <td className="DBP_Table_Column" style={{ flex: flexs[1] }}>
                  {`${numberDisplayThousand(item.price)}đ/ ${unit.description}`}
                </td>
                <td className="DBP_Table_Column" style={{ flex: flexs[2] }}>
                  {!this.isEditOrder ? (
                    item.quantity
                  ) : (
                    <Input
                      value={item.quantity.toString()}
                      onChange={this.onChangeQty(index)}
                    />
                  )}
                </td>
                <td
                  className="DBP_Table_Column"
                  style={{
                    justifyContent: "flex-end",
                    flex: flexs[3],
                  }}
                >
                  {numberDisplayThousand(item.price * item.quantity)}đ
                </td>
              </tr>
            );
          })}
        </table>

        {/* Update Product */}
        {this.isEditOrder ? (
          <>
            <div className="UCP_SelectRow">
              <div className="UCP_SelectTitle">
                <FaShippingFast className="Icon" />
                Hình thức giao hàng
              </div>
              <div className="UCP_SelectContent">
                <Radio.Group
                  onChange={this.onChangeAddressShip}
                  value={this.state.shipping_type}
                >
                  {ListShipping.slice(0, 1).map((shipping, index) => {
                    return <Radio value={shipping.type}>{shipping.name}</Radio>;
                  })}
                </Radio.Group>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 30,
                // justifyContent: "flex-end",
              }}
            >
              <div
                className="DPB_BtnCancel"
                onClick={() => {
                  this.props.init();
                  this.props.setModalSelectProductState(true);
                }}
                style={{
                  backgroundColor: "#074f51",
                  marginRight: 5,
                }}
              >
                Thêm sản phẩm
              </div>

              <div
                className="DPB_BtnCancel"
                onClick={this.onUpdateOrder}
                style={{
                  backgroundColor: "orange",
                  marginRight: 5,
                }}
              >
                Cập nhật đơn hàng
              </div>

              <div
                className="DPB_BtnCancel"
                style={{
                  backgroundColor: "orange",
                  marginRight: 5,
                  flexDirection: "row",
                }}
              >
                <div>Thời gian giao hàng dự kiến</div>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder="Chọn"
                    style={{ marginLeft: 5 }}
                    defaultValue={this.estimatedDeliveryTime}
                    onChange={(e) => {
                      this.estimatedDeliveryTime = e;
                    }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </>
        ) : null}

        {/* Bottom */}

        <div className="DBP_SumInfo">
          <div className="DBP_SumInfo_Row">
            <div className="DBP_SumInfo_Title">Tạm tính</div>
            <div className="DBP_SumInfo_Value">
              {`${numberDisplayThousand(total)}đ`}
            </div>
          </div>
          <div className="DBP_SumInfo_Row">
            <div className="DBP_SumInfo_Title">Phí vận chuyển</div>
            <div className="DBP_SumInfo_Value">
              {`${numberDisplayThousand(shippingPrice)}đ`}
            </div>
          </div>
          <div className="DBP_SumInfo_Row">
            <div className="DBP_SumInfo_Title">Giảm giá</div>
            <div className="DBP_SumInfo_Value">
              {discountPrice > 0
                ? `-${numberDisplayThousand(discountPrice)}đ`
                : `${numberDisplayThousand(discountPrice)}đ`}
            </div>
          </div>
          <div className="DBP_SumInfo_Row">
            <div className="DBP_SumInfo_Title">Số điểm khuyến mãi sử dụng</div>
            <div className="DBP_SumInfo_Value">
              {numberDisplayThousand(usedPoints)}
            </div>
          </div>
          <div className="DBP_SumInfo_Row">
            <div className="DBP_SumInfo_Title">Tổng cộng</div>
            <div
              className="DBP_SumInfo_Value"
              style={{
                color: "green",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {`${numberDisplayThousand(finalPrice)}đ`}
            </div>
          </div>
        </div>

        <ModalSelectProduct onClickProduct={this.onChooseProduct} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(AdminCreateOrderActions.action.loadListProduct());
    },
    // modal
    setModalSelectProductState: (state) => {
      dispatch(
        AdminCreateOrderActions.action.setModalSelectProductState(state)
      );
    },
  };
};

export default connect(undefined, mapDispatchToProps, undefined, {
  forwardRef: true,
})(OrderDetailBottom);
