import ApiUtils from "services/ApiUtils";

const apiName = {
  SHIPPING_FEE_AHAMOVE: "/shipping/getShippingFeeAhamove",
  DISCOUNT_OF_VOUCHER: "/voucher/getPriceDiscountOfVoucher",
  VOUCHER_AVAILABLE_ON_USER: "/voucher/getVoucherAvailableOnUser",
  REGISTER: "/auth/register?linkAffiliate=",
  CREAT_ORDER: "/admin/managerOrder/createOrder",
  PRODUCT_BY_CODE: "/products/byCode/",
};

export const apiGetShippingFeeAhamove = async (addressId, serviceId) => {
  const res = await ApiUtils.fetch(
    apiName.SHIPPING_FEE_AHAMOVE,
    { addressId, serviceId },
    { isAuth: true }
  );
  return res?.data || undefined;
};

export const apiGetPriceDiscountOfVoucher = async (params) => {
  const res = await ApiUtils.fetch(apiName.DISCOUNT_OF_VOUCHER, params, {
    isAuth: true,
  });
  return res?.data || undefined;
};

export const apiGetVoucherAvailableOnUser = async (params) => {
  const res = await ApiUtils.fetch(apiName.VOUCHER_AVAILABLE_ON_USER, params, {
    isAuth: true,
  });
  return res?.data || undefined;
};

export const apiAdminCreateUser = async (params) => {
  const res = await ApiUtils.post(
    apiName.REGISTER + params.linkAffiliate,
    params
  );
  return res;
};

export const apiAdminCreateOrder = async (params) => {
  const res = await ApiUtils.post(apiName.CREAT_ORDER, params, {
    isAuth: true,
  });
  return res?.data || undefined;
};

export const apiGetInfoProductByCode = async (productCode) => {
  const res = await ApiUtils.fetch(
    apiName.PRODUCT_BY_CODE + productCode,
    undefined,
    {
      isAuth: true,
    }
  );
  return res?.data || undefined;
};
