import "moment/locale/vi";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import router_links from "../../../../../../const/router_link";
import { getImageUrl } from "../../../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../../../services/utils/helper";
import "../index.scss";

const flexs = [25, 10, 10, 10];

export default class OrderDetailBundledProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const products = this.props?.products || [];

    if (products.length === 0) {
      return null;
    }

    return (
      <>
        <table className="DBP_ListItems">
          <tr className="DBP_Table_HeaderRow">
            <th
              className="DBP_Table_HeaderColumn"
              style={{
                justifyContent: "center",
                width: 70,
                marginRight: 10,
              }}
            >
              Sản phẩm tặng kèm
            </th>
            <th
              className="DBP_Table_HeaderColumn"
              style={{
                justifyContent: "flex-start",
                flex: flexs[0],
              }}
            >
              {/* Sản phẩm */}
            </th>
            <th className="DBP_Table_HeaderColumn" style={{ flex: flexs[1] }}>
              Giá
            </th>
            <th className="DBP_Table_HeaderColumn" style={{ flex: flexs[2] }}>
              Số lượng
            </th>
            <th
              className="DBP_Table_HeaderColumn"
              style={{
                justifyContent: "flex-end",
                flex: flexs[3],
              }}
            >
              Tạm tính
            </th>
          </tr>

          {products.map((item, index) => {
            const unit = item.productUnit ? item.productUnit : {};

            return (
              <tr
                className="DBP_TableRow"
                style={{
                  borderBottom:
                    index === products.length - 1
                      ? "none"
                      : "1px solid #e2e2e2",
                }}
              >
                <td
                  className="DBP_Table_Column"
                  style={{
                    justifyContent: "flex-start",
                    marginRight: 10,
                  }}
                >
                  <Link
                    to={`${router_links.U_DETAIL_PRODUCT}/${item.productId}`}
                  >
                    <img
                      alt="image"
                      src={getImageUrl(item.thumbnail)}
                      className="DBP_Image_Product"
                    />
                  </Link>
                </td>
                <td
                  className="DBP_Table_Column"
                  style={{
                    justifyContent: "flex-start",
                    flex: flexs[0],
                  }}
                >
                  <Link
                    to={`${router_links.U_DETAIL_PRODUCT}/${item.productId}`}
                    style={{
                      color: "black",
                    }}
                  >
                    {item.productName}
                  </Link>
                </td>
                <td className="DBP_Table_Column" style={{ flex: flexs[1] }}>
                  {`${numberDisplayThousand(item.price)}đ/ ${unit.description}`}
                </td>
                <td className="DBP_Table_Column" style={{ flex: flexs[2] }}>
                  {item.qty}
                </td>
                <td
                  className="DBP_Table_Column"
                  style={{
                    justifyContent: "flex-end",
                    flex: flexs[3],
                  }}
                >
                  {numberDisplayThousand(item.price * item.qty)}đ
                </td>
              </tr>
            );
          })}
        </table>
      </>
    );
  }
}
