import { Button, List } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { PureComponent } from "react";
import { apiGetVoucherAvailableOnUser } from "../create-order.service";
import "./index.scss";

const ITEM_LOAD = 100;

export default class CreateOrderPopupChooseVoucher extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      vouchers: [],
      loading: true,
      page: 1,
    };
  }

  productIds = [];

  open = (productIds) => {
    this.productIds = productIds;
    this.setState({ modalVisible: true });
    this.getData(1);
  };

  onCancel = () => {
    this.setState({
      loading: true,
      page: 1,
      modalVisible: false,
    });
  };

  getData = async (pageIndex) => {
    const params = {
      pageIndex,
      pageSize: ITEM_LOAD,
      inProductIds: this.productIds,
    };

    const res = await apiGetVoucherAvailableOnUser(params);
    this.setState({
      vouchers: res || [],
      loading: false,
    });
  };

  onSelectItem = (item) => {
    this.props.onSelect && this.props.onSelect(item);
    this.onCancel();
  };

  render() {
    const { modalVisible, vouchers, loading } = this.state;
    return (
      <Modal
        visible={modalVisible}
        onOk={this.onPressYes}
        onCancel={this.onCancel}
        centered
        width={1300}
        bodyStyle={{ height: 600, overflow: "scroll" }}
        footer={[
          <Button
            size="large"
            key="back"
            onClick={this.onCancel}
            style={{
              background: "#F34949",
              borderRadius: "3px",
              color: "#fff",
              border: "none",
            }}
          >
            Trở lại
          </Button>,
        ]}
      >
        <div className="mt-3">
          <List
            grid={{ gutter: 16, column: 4 }}
            size="large"
            loading={loading}
            style={{ marginLeft: 8, marginRight: 8, flexDirection: "row" }}
            dataSource={vouchers}
            renderItem={(item, i) => {
              return (
                <div
                  className="UCP_DiscountItem"
                  onClick={() => this.onSelectItem(item)}
                >
                  <div className="UCP_DiscountItem_PercentCol">
                    {`${item?.discount}%`}

                    <div className="UCP_Discount_Circle_Top" />
                    <div className="UCP_Discount_Circle_Bottom" />
                  </div>
                  <div className="UCP_DiscountItem_InfoCol">
                    <div className="UCP_DiscoutItem_Code">{item?.code}</div>
                    <div className="UCP_Discountitem_Description">
                      {item?.description}
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </Modal>
    );
  }
}
