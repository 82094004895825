import { Button, Col, InputNumber, List, Radio, Switch } from "antd";
import { history } from "App/redux/store";
import { AdminListPaymentTypes } from "const/payment_type";
import router_links from "const/router_link";
import { isEmpty } from "helpers/text.helper";
import React, { Component } from "react";
import BarcodeReader from "react-barcode-reader";
import { FaCheck, FaTimes, FaTimesCircle } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { numberDisplayThousand } from "services/utils/helper";
import NotificationsService from "services/utils/notifycationHelper";
import AdminCreateOrderActions from "../../../redux/admin_create_order/actions";
import CreateVoucherPopupChooseProduct from "../create-voucher/components/create-voucher-popup-choose-product";
import { apiGetListProducts } from "../create-voucher/model/create-voucher.service";
import ModalSelectAddress from "../list_order/create_order_page/modal_select_address";
import ModalSelectProduct from "../list_order/create_order_page/modal_select_product";
import ModalSelectUser from "../list_order/create_order_page/modal_select_user";
import CreateOrderItemComponent from "./components/create-order-item";
import CreateOrderPopupChooseVoucher from "./components/create-order-popup-choose-voucher";
import ListProductDonate from "./components/list-product-donate";
import ModalAddUserComponent from "./components/modal-add-user";
import {
  apiAdminCreateOrder,
  apiGetInfoProductByCode,
  apiGetPriceDiscountOfVoucher,
  apiGetShippingFeeAhamove,
} from "./create-order.service";
import { dataListShipping, EShippingType } from "./type";

const ITEM_LOAD = 1000;

class AdminCreateOrder extends Component {
  tempProducts = [];

  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
      products: [],
      productIds: [],
      shipData: dataListShipping,
      shipping: dataListShipping[0],
      voucherInfo: undefined,
      pointUse: 0,
      isScanbarCode: true,
    };
  }

  componentDidMount = () => {
    this.props.init();
  };

  onSelectProductIds = (productIds) => {
    this.setState({ loading: true, productIds, page: 1 }, () => {
      this.onGetListProducts(1);
    });
  };

  onSelectUser = (user) => {
    this.setState({
      user,
      pointUse: 0,
      voucherInfo: undefined,
      shipping: dataListShipping[0],
    });
  };

  onSelectVoucher = async (voucher) => {
    const tempProduct = [];

    for (const e of this.state.products) {
      tempProduct.push({
        _id: e._id,
        qty: e.qtyWareHouse,
      });
    }

    const res = await apiGetPriceDiscountOfVoucher({
      voucherCode: voucher.code,
      products: tempProduct,
      userId: this.state.user?._id,
    });

    if (res?.error) {
      NotificationsService.error(res?.error);
    }

    this.setState({
      voucherInfo: res || undefined,
    });
  };

  onGetListProducts = async (pageIndex) => {
    const params = {
      pageIndex,
      pageSize: ITEM_LOAD,
      inIds: this.state.productIds,
      isGetAllProduct: true,
    };

    let { products, total } = await apiGetListProducts(params);

    if (this.tempProducts.length > 0) {
      const newProducts = [];
      for (const e of products) {
        const productTemp = this.tempProducts.find((j) => j._id === e._id);
        newProducts.push({
          ...e,
          ...productTemp,
        });
      }

      products = newProducts;
    }

    this.tempProducts = products;

    this.totalProduct = total;

    this.setState({
      products,
      loading: false,
    });
  };

  onScanBarCode = async (productCode) => {
    if (!this.state.isScanbarCode) {
      return;
    }

    const productInfo = await apiGetInfoProductByCode(productCode);
    if (!productInfo) {
      return;
    }

    let products = [...this.state.products];

    const index = products.findIndex((e) => e._id === productInfo._id);
    if (index >= 0) {
      products[index] = {
        ...products[index],
        ...productInfo,
        qtyWareHouse: (products[index]?.qtyWareHouse || 0) + 1,
      };
    } else {
      products.push({
        ...productInfo,
        qtyWareHouse: 1,
      });
    }

    this.tempProducts = products;

    this.setState({ products }, () => {
      if (this.state.voucherInfo) {
        this.onSelectVoucher(this.state.voucherInfo);
      }
    });
  };

  onUpdateItem = (item) => {
    let products = [...this.state.products];

    const index = products.findIndex((e) => e._id === item._id);
    if (index >= 0) {
      products[index] = {
        ...products[index],
        ...item,
      };
    }

    this.tempProducts = products;

    this.setState({ products }, () => {
      if (this.state.voucherInfo) {
        this.onSelectVoucher(this.state.voucherInfo);
      }
    });
  };

  onSelectAddress = async (address) => {
    const shipDataTemp = [...this.state.shipData];

    try {
      const express = await apiGetShippingFeeAhamove(
        address._id,
        EShippingType.EXPRESS
      );

      shipDataTemp[1].data = express || undefined;

      const bike = await apiGetShippingFeeAhamove(
        address._id,
        EShippingType.BIKE
      );
      shipDataTemp[2].data = bike || undefined;

      const pool = await apiGetShippingFeeAhamove(
        address._id,
        EShippingType.POOL
      );
      shipDataTemp[3].data = pool || undefined;

      this.setState({
        shipData: shipDataTemp,
        loading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  onCreateOrder = async () => {
    const { user, products, shipping, voucherInfo, pointUse } = this.state;
    const { select_address, order, bundled_products } = this.props;

    const tempProduct = [];

    for (const e of products) {
      if (e.qtyWareHouse) {
        tempProduct.push({
          _id: e._id,
          qty: e.qtyWareHouse,
        });
      }
    }

    const params = {
      userId: user?._id,
      products: tempProduct,
      addressId: select_address?._id,
      paymentMethod: order.paymentMethod,
      pointUse,
      serviceId: shipping?.type,
      voucherCode: voucherInfo?.code || undefined,
      bundledProducts: [],
    };

    if (isEmpty(params.userId)) {
      NotificationsService.error("Vui lòng chọn người dùng");
      return;
    }

    if (params.products.length === 0) {
      NotificationsService.error("Vui lòng chọn sản phẩm");
      return;
    }

    if (bundled_products && bundled_products.length > 0) {
      for (const e of bundled_products) {
        if (e.quantity) {
          params.bundledProducts.push({
            ...e?.product,
            _id: e?.product?._id,
            qty: Number(e.quantity),
          });
        }
      }
    }

    const res = await apiAdminCreateOrder(params);

    if (res?._id) {
      history.push(`${router_links.A_LIST_ORDER_DETAIl}/${res._id}`);
      NotificationsService.success("Tạo đơn hàng thành công");
      return;
    }
    NotificationsService.error("Tạo đơn hàng thất bại");
  };

  getTotalPriceProducts() {
    const { products, shipping, voucherInfo, pointUse } = this.state;

    let priceProduct = 0;
    let priceShipping = shipping?.data?.total_price || 0;
    let total = 0;
    let priceDiscount = voucherInfo?.totalPriceDiscountOfCart || 0;
    const cadoxuUse = Math.floor((priceDiscount || 0) / 1000);

    for (const e of products) {
      let totalPrice = 0;

      if (e.qtyWareHouse) {
        totalPrice = e.price * e.qtyWareHouse;
      }

      priceProduct += totalPrice;
    }

    total = priceProduct + priceShipping;

    if (priceDiscount) {
      total = total - priceDiscount;
    }

    if (pointUse) {
      total = total - pointUse * 1000;
    }

    if (total < 0) {
      total = 0;
    }

    return {
      priceProduct,
      priceShipping,
      total,
      priceDiscount,
      cadoxuUse,
    };
  }

  render() {
    const { select_address, order } = this.props;

    const {
      user,
      products,
      productIds,
      shipData,
      shipping,
      voucherInfo,
      pointUse,
      isScanbarCode,
    } = this.state;

    return (
      <div className="CreateOrderPage">
        <div className="COP_Title">Tạo mới đơn hàng</div>
        <div className="COP_RowInfo">
          <div className="COP_Row_Title">Người dùng</div>
          <div className="COP_Row_Content">
            {user ? (
              <span>
                {`${user.fullName} - ${user.phone}`}

                <span style={{ color: "orange", fontWeight: "bold" }}>
                  {` (${numberDisplayThousand(
                    Math.floor(user.cadoxu)
                  )} CADOXU)`}
                </span>
              </span>
            ) : (
              "Chưa có người dùng nào được chọn"
            )}

            <div
              className="COP_ROW_Action"
              onClick={() => {
                this.props.setModalSelectUserState(true);
              }}
            >
              Chọn
            </div>

            <div
              className="COP_ROW_Action"
              onClick={() => {
                this.refModalAddUser?.open?.();
              }}
            >
              Thêm mới người dùng
            </div>
          </div>
        </div>
        {order.paymentMethod !== "DIRECT" && (
          <div className="COP_RowInfo">
            <div className="COP_Row_Title">Địa chỉ giao hàng</div>
            <div className="COP_Row_Content">
              {select_address
                ? `${select_address.fullAddress}, ${select_address.wardName}, ${select_address.districtName}, ${select_address.provinceName}`
                : "Chưa có địa chỉ nào được chọn"}
              <div
                className="COP_ROW_Action"
                onClick={() => {
                  this.props.setModalSelectAddressState(true);
                }}
              >
                Chọn
              </div>
            </div>
          </div>
        )}

        <div
          className="COP_RowInfo"
          style={{
            alignItems: "flex-start",
            marginTop: 5,
          }}
        >
          <div className="COP_Row_Title">
            {`${isScanbarCode ? "Tắt" : "Bật"} quét mã vạch`}
          </div>

          <div className="COP_Row_Content">
            <Switch
              checked={isScanbarCode}
              onChange={(e) => {
                this.setState({ isScanbarCode: e });
              }}
            />
          </div>
        </div>

        <div
          className="COP_RowInfo"
          style={{
            alignItems: "flex-start",
          }}
        >
          <div
            className="COP_Row_Title"
            style={{
              marginTop: 10,
            }}
          >
            Sản phẩm mua
          </div>

          <div className="COP_Row_Content">
            <Button
              type="primary"
              onClick={() => {
                this.refModalChooseProduct.open(productIds || []);
              }}
            >
              Chọn sản phẩm
            </Button>
          </div>
        </div>
        {products.length > 0 ? (
          <div>
            <List
              grid={{ gutter: 16, column: 2 }}
              size="large"
              style={{
                flexDirection: "row",
                marginBottom: 20,
              }}
              dataSource={products}
              renderItem={(item, i) => {
                return (
                  <Col span={22}>
                    <CreateOrderItemComponent
                      item={item}
                      onUpdateItem={this.onUpdateItem}
                    />
                  </Col>
                );
              }}
            />
          </div>
        ) : null}
        <div
          className="COP_RowInfo"
          style={{
            alignItems: "flex-start",
          }}
        >
          <div
            className="COP_Row_Title"
            style={{
              marginTop: 10,
            }}
          >
            Sản phẩm tặng kèm
          </div>
          <div className="COP_Row_Content">
            <ListProductDonate />
          </div>
        </div>
        <div className="COP_RowInfo">
          <div className="COP_Row_Title">
            Số điểm sử dụng
            {user ? (
              <span style={{ color: "orange", fontWeight: "bold" }}>
                {` (${numberDisplayThousand(Math.floor(user?.point))} Điểm)`}
              </span>
            ) : null}
          </div>
          <div className="COP_Row_Content">
            <InputNumber
              disabled={!user || !user.point}
              min={0}
              placeholder="Nhập số điểm"
              max={user?.point || 0}
              style={{ width: 200 }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={pointUse}
              onChange={(val) => {
                this.setState({ pointUse: val });
              }}
            />
          </div>
        </div>
        {user && products.length > 0 ? (
          <div className="COP_RowInfo">
            <div className="COP_Row_Title">Mã khuyến mại</div>
            <div className="COP_Row_Content">
              {voucherInfo === undefined ? (
                <>
                  <div className="COP_Row_Content">
                    <Button
                      type="primary"
                      onClick={() => {
                        this.refModalChooseVoucher.open(productIds);
                      }}
                    >
                      Chọn khuyến mại
                    </Button>
                  </div>
                </>
              ) : (
                <div className="UCP_DiscountItem">
                  <div className="UCP_DiscountItem_PercentCol">
                    {`${voucherInfo?.discount}%`}

                    <div className="UCP_Discount_Circle_Top" />
                    <div className="UCP_Discount_Circle_Bottom" />
                  </div>
                  <div className="UCP_DiscountItem_InfoCol">
                    <div className="UCP_DiscoutItem_Code">
                      {voucherInfo?.code}
                    </div>
                    <div className="UCP_Discountitem_Description">
                      {voucherInfo?.description}
                    </div>
                  </div>
                  <div className="UCP_DiscountItem_Action">
                    <FaTimes
                      className="UCP_DiscountItem_Icon"
                      onClick={() => {
                        this.setState({ voucherInfo: undefined });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
        <div className="COP_RowInfo">
          <div className="COP_Row_Title">Phương thức thanh toán</div>
          <div className="COP_Row_Content">
            <Radio.Group
              value={order.paymentMethod}
              onChange={(e) => {
                this.setState({
                  shipping: dataListShipping[0],
                });

                this.props.updateCurrentOrderInfo({
                  paymentMethod: e.target.value,
                });

                this.props.getShippingInfo();
              }}
            >
              {AdminListPaymentTypes.map((payment) => {
                return <Radio value={payment.type}>{payment.name}</Radio>;
              })}
            </Radio.Group>
          </div>
        </div>
        {order.paymentMethod !== "DIRECT" && (
          <div className="COP_RowInfo">
            <div className="COP_Row_Title">Phương thức giao hàng</div>
            <div className="COP_Row_Content">
              <Radio.Group
                defaultValue={shipping}
                value={shipping}
                onChange={(e) => {
                  this.setState({ shipping: e.target.value });
                }}
              >
                {shipData.map((e, index) => {
                  if (
                    (e.type !== EShippingType.WARE_HOUSE && !e.data) ||
                    (e.type !== EShippingType.WARE_HOUSE && !select_address)
                  )
                    return null;
                  return <Radio value={e}>{e.name}</Radio>;
                })}
              </Radio.Group>
            </div>
          </div>
        )}
        <div className="COP_RowInfo">
          <div className="COP_Row_Title">Tổng tiền hàng</div>
          <div className="COP_Row_Content" style={{ fontWeight: "bold" }}>
            {`${numberDisplayThousand(
              this.getTotalPriceProducts().priceProduct
            )} đ`}
          </div>
        </div>
        <div className="COP_RowInfo">
          <div className="COP_Row_Title">
            {`Số xu sử dụng (${this.getTotalPriceProducts().cadoxuUse} CADOXU)`}
          </div>
          <div className="COP_Row_Content" style={{ fontWeight: "bold" }}>
            {`${numberDisplayThousand(
              this.getTotalPriceProducts().priceDiscount
            )} đ`}
          </div>
        </div>
        <div className="COP_RowInfo">
          <div className="COP_Row_Title">{`Số điểm sử dụng (${pointUse} Điểm)`}</div>
          <div className="COP_Row_Content" style={{ fontWeight: "bold" }}>
            {`${numberDisplayThousand(pointUse * 1000)} đ`}
          </div>
        </div>
        <div className="COP_RowInfo">
          <div className="COP_Row_Title">Phí ship</div>
          <div className="COP_Row_Content" style={{ fontWeight: "bold" }}>
            {`${numberDisplayThousand(
              this.getTotalPriceProducts().priceShipping
            )} đ`}
          </div>
        </div>
        <div className="COP_RowInfo">
          <div className="COP_Row_Title">Thành tiền</div>
          <div className="COP_Row_Content" style={{ fontWeight: "bold" }}>
            {`${numberDisplayThousand(this.getTotalPriceProducts().total)} đ`}
          </div>
        </div>
        <div className="COP_Action">
          <div
            className="COP_Action_Btn"
            style={{
              backgroundColor: "#074f51",
              color: "white",
            }}
            onClick={this.onCreateOrder}
          >
            <FaCheck className="COP_Row_Action_Icon" />
            Tạo đơn hàng
          </div>
          <Link to={router_links.A_LIST_ORDER} className="COP_Action_Btn">
            <FaTimesCircle className="COP_Row_Action_Icon" />
            Huỷ
          </Link>
        </div>
        <ModalSelectAddress onSelectAddress={this.onSelectAddress} />
        <ModalSelectUser onSelectUser={this.onSelectUser} />
        <ModalSelectProduct />
        <CreateVoucherPopupChooseProduct
          ref={(ref) => (this.refModalChooseProduct = ref)}
          onSelect={this.onSelectProductIds}
          paramsFilter={{ isGetAllProduct: true }}
        />
        <CreateOrderPopupChooseVoucher
          ref={(ref) => (this.refModalChooseVoucher = ref)}
          onSelect={this.onSelectVoucher}
        />
        <ModalAddUserComponent
          ref={(ref) => (this.refModalAddUser = ref)}
          onCreateUserSuccess={this.onSelectUser}
        />

        {isScanbarCode ? (
          <BarcodeReader onScan={this.onScanBarCode} onError={() => {}} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.admin_create_order;

  return {
    select_user: _state.select_user,
    select_address: _state.select_address,
    order: _state.order,
    total: _state.total,
    bundled_products: _state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(AdminCreateOrderActions.action.setDefaultCurrentOrderInfo());
      dispatch(AdminCreateOrderActions.action.loadListUser());
      dispatch(AdminCreateOrderActions.action.loadListProduct());
    },
    setModalSelectUserState: (state) => {
      dispatch(AdminCreateOrderActions.action.setModalSelectUserState(state));
    },
    setModalSelectAddressState: (state) => {
      dispatch(
        AdminCreateOrderActions.action.setModalSelectAddressState(state)
      );
    },
    updateCurrentOrderInfo: (info) => {
      dispatch(AdminCreateOrderActions.action.updateCurrentOrderInfo(info));
    },
    getShippingInfo: () => {
      dispatch(AdminCreateOrderActions.action.getShippingInfo());
    },
  };
};

const AdminCreateOrderScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCreateOrder);

export default AdminCreateOrderScreen;
