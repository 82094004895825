import {
  EnvironmentOutlined,
  FacebookOutlined,
  LinkOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Card, Layout, List, Typography } from "antd";
import React from "react";

const { Content } = Layout;
const { Link, Title } = Typography;

const CompanyInfo = () => (
  <Card
    title={
      <Title level={4} style={{ margin: 0, fontWeight: "bold" }}>
        Cadosa Store
      </Title>
    }
    style={{ marginBottom: 20, fontWeight: "bold" }}
    titleStyle={{ fontWeight: "bold" }}
  >
    <p style={{ fontWeight: "600" }}>Cánh đồng sạch cho mọi nhà</p>

    <p>
      <strong>Website:</strong>{" "}
      <Link href="https://cadosa.vn" target="_blank">
        <LinkOutlined style={{ verticalAlign: "middle" }} /> Cadosa.vn
      </Link>
    </p>
    <p>
      <strong>Fanpage Facebook:</strong>{" "}
      <Link href="https://www.facebook.com/cadosa.vn" target="_blank">
        <FacebookOutlined style={{ verticalAlign: "middle" }} /> Cadosa.vn
      </Link>
    </p>
    <p>
      <strong>Zalo Mini App:</strong>{" "}
      <Link href="https://zalo.me/s/841496326030914170/" target="_blank">
        Cadosa.vn
      </Link>
    </p>
  </Card>
);

const storeData = [
  {
    name: "Cơ sở 1",
    address: "Số 16 lô TT02 khu đô thị HD MON Hàm Nghi, Thành phố Hà Nội",
    phone: "032 9953955",
    mapLink: "https://maps.app.goo.gl/TWFz4wngdnKNcyKb8",
  },
  {
    name: "Cơ sở 2",
    address: "Số 16 toà S303 Vinsmart City Tây Mỗ, Thành phố Hà Nội",
    phone: "038 2898766",
    mapLink: "https://maps.app.goo.gl/amSmV6kj6owxWXGQ7",
  },
];

const StoreList = () => (
  <List
    grid={{ gutter: 16, column: 1 }}
    dataSource={storeData}
    renderItem={(store) => (
      <List.Item>
        <Card
          title={
            <Title level={5} style={{ margin: 0, fontWeight: "bold" }}>
              {store.name}
            </Title>
          }
        >
          <p>
            <strong>Địa chỉ:</strong> {store.address}
          </p>
          <p>
            <strong>Số điện thoại:</strong>{" "}
            <Link href={`tel:${store.phone}`}>
              <PhoneOutlined style={{ verticalAlign: "middle" }} />{" "}
              {store.phone}
            </Link>
          </p>
          <p>
            <Link href={store.mapLink} target="_blank">
              <EnvironmentOutlined style={{ verticalAlign: "middle" }} /> Xem
              trên bản đồ
            </Link>
          </p>
        </Card>
      </List.Item>
    )}
  />
);

const StoreListPage = () => (
  <Layout style={{ minHeight: "100vh", padding: "10px" }}>
    <Content style={{ marginTop: "10px" }}>
      <CompanyInfo />
      <StoreList />
    </Content>
  </Layout>
);

export default StoreListPage;
