export const EShippingType = {
  EXPRESS: "HAN-EXPRESS",
  BIKE: "HAN-BIKE",
  POOL: "HAN-POOL",
  WARE_HOUSE: "WARE_HOUSE",
};

export const dataListShipping = [
  {
    type: EShippingType.WARE_HOUSE,
    name: "Nhận hàng tại kho của Cadosa",
  },
  {
    type: EShippingType.EXPRESS,
    name: "Giao gần",
  },
  {
    type: EShippingType.BIKE,
    name: "Giao siêu tốc",
  },
  {
    type: EShippingType.POOL,
    name: "Giao siêu rẻ",
  },
];
