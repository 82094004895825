import React from "react";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
// component
import Button from "../../../../components/button";
import { convertDateToDatetime } from "../../../../services/utils/helper";
// scss
import "./index.scss";
import ModalAddNotifySystem from "./modal-add-notify-system";
import { apiGetNotifySystem } from "./notify-system.service";

const list_flexs = [3, 16, 20, 10, 15];

export default class NotifySystem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      notify: [],
    };
  }

  componentDidMount() {
    this.getNotify();
  }

  getNotify = async () => {
    const notify = await apiGetNotifySystem();
    this.setState({ notify });
  };

  onAdd = () => {
    this.refModal.open();
  };

  render() {
    const { notify } = this.state;
    return (
      <>
        <div className="ManagerListLevelUser">
          <div className="MLLU_Actions">
            <Button
              label="Thêm mới thông báo"
              icon={<FaPlusCircle style={{ marginRight: "4px" }} />}
              className="MLLU_AddButton"
              onClick={this.onAdd}
            />
          </div>

          <table className="CDS_Table" style={{}}>
            <tr className="CDS_TableRowHeader">
              <th
                className="CDS_HeaderText"
                style={{
                  flex: list_flexs[0],
                  marginRight: 10,
                }}
              >
                STT
              </th>
              <th
                className="CDS_HeaderText"
                style={{
                  flex: list_flexs[1],
                }}
              >
                Tên
              </th>
              <th
                className="CDS_HeaderText"
                style={{
                  flex: list_flexs[2],
                  width: 50,
                }}
              >
                Mô tả
              </th>

              <th className="CDS_HeaderText" style={{ flex: list_flexs[3] }}>
                Thời gian gửi
              </th>

              <th className="CDS_HeaderText" style={{ flex: list_flexs[4] }}>
                Hành động
              </th>
            </tr>

            {notify.map((entity, index) => {
              const timeCreate =
                entity.payload && entity.payload.timeSend
                  ? new Date(entity.payload.timeSend)
                  : entity.createdAt;

              return (
                <tr className="CDS_TableRowHeader">
                  <th
                    className="CDS_HeaderText"
                    style={{
                      flex: list_flexs[0],
                      marginRight: 10,
                    }}
                  >
                    {index + 1}
                  </th>
                  <th
                    className="CDS_HeaderText"
                    style={{
                      flex: list_flexs[1],
                    }}
                  >
                    {entity.title}
                  </th>
                  <th
                    className="CDS_HeaderText"
                    style={{
                      flex: list_flexs[2],
                      width: 50,
                    }}
                  >
                    {entity.content}
                  </th>

                  <th
                    className="CDS_HeaderText"
                    style={{ flex: list_flexs[3] }}
                  >
                    {convertDateToDatetime(timeCreate, "yyyy-MM-dd HH:mm")}
                  </th>

                  <th
                    className="CDS_HeaderText"
                    style={{ flex: list_flexs[4] }}
                  >
                    <div
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: "#074f51",
                        color: "white",
                      }}
                      // onClick={() => this.onClickButtonEdit(entity)}
                    >
                      <FaEdit className="APL_Table_Icon" />
                      <span className="superMiniHide">Sửa</span>
                    </div>

                    <div
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                      }}
                      // onClick={() => {
                      //   this.setState({
                      //     currentEntity: entity,
                      //     modalConfirmDeleteState: true,
                      //   });
                      // }}
                    >
                      <FaTrash className="APL_Table_Icon" />
                      <span className="superMiniHide">Xoá</span>
                    </div>
                  </th>
                </tr>
              );
            })}
          </table>
        </div>

        <ModalAddNotifySystem ref={(ref) => (this.refModal = ref)} />
      </>
    );
  }
}
