const colors = {
  primaryColor: "#074f51",
  secondColor: "#FF5656",
  white: "#fff",
  white50: "rgba(255, 255, 255, 0.5)",
  white70: "rgba(255, 255, 255, 0.7)",
  borderC: "#ccc",
  borderD: "#ddd",
  borderE: "#eee",
  borderFE: "#FEFEFE",
  borderF9: "#F9F9F9",
  borderEF: "#EFEFEF",
  black: "#000",
  black1: "#111",
  black2: "#222",
  black3: "#333333",
  black5: "#555",
  black7: "#777",
  black9: "#999",
  black10p: "#11111110",
  black40p: "rgba(0, 0, 0, 0.4)",
  black60p: "rgba(0, 0, 0, 0.6)",
  black70p: "rgba(0, 0, 0, 0.7)",
  blue600: "#1e88e5",
  blue: "#0071BC",
  transparent: "transparent",
  text: "#020306",
};

export default colors;
